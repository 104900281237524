import React from "react";
import { getApartados, getContentBlockToShow } from "./ExerciseBlocks";

const StatementBlock = (props) => {
  const { exerciseBlock, exerciseIndex } = props;
  return (
    <section className="row exerciseBlock statement">
      {getApartados(exerciseBlock.fields.statement).map((apartado, index) => {
        return (
          <div className="col-12" key={`${exerciseBlock.sys.id}-${index}`}>
            <div className="container">
              <div className="row card">
                {apartado.map((contentBlock, indexb) => {
                  if (
                    index === 0 &&
                    indexb === 0 &&
                    contentBlock.content != null &&
                    contentBlock.content[0] != null
                  ) {
                    contentBlock.content[0].value = `${exerciseIndex}. ${contentBlock.content[0].value}`;
                  }
                  return (
                    <div
                      className="col-12"
                      key={`${exerciseBlock.sys.id}-${index}-${indexb}`}
                    >
                      {getContentBlockToShow(
                        contentBlock,
                        false,
                        `${exerciseBlock.sys.id}-${index}-${indexb}`
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default StatementBlock;
