import React, {Component} from "react";
import "../scss/pdf/App.scss"

class PDFPage extends Component{
    render() {
        const {element} = this.props;

        return (
            <div className="layout pdf">
                {element}
            </div>
        );
    }
}

export default PDFPage;