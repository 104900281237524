import React, { Component } from "react";
import { withRouter } from "../Utils/withRouter";
import { Link } from "react-router-dom";
import { DeliveryClient } from "./Clients";

class Grades extends Component {
  constructor() {
    super();
    this.state = { grades: [] };
  }

  componentDidMount() {
    this.props.i18n.changeLanguage(this.props.match.params.locale);
    this.getData();
  }

  getData() {
    this.getCountries();
  }

  getCountries() {
    DeliveryClient.getOrCreate()
      .getEntries({
        content_type: "grades",
        locale: this.props.i18n.language,
      })
      .then((entries) => {
        this.setState({ grades: entries.items });
      });
  }

  render() {
    const { i18n, t } = this.props;

    return (
      <div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12">
              <div className="text-center">
                <h2>{t("navbar.grades")}</h2>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <table className="table table-striped table-bordered table-hover">
                <tbody>
                  {this.state.grades.map((grade, index) => {
                    //Carles
                    // if (
                    //   grade.fields.name == "ESO1" ||
                    //   grade.fields.name == "ESO2" ||
                    //   grade.fields.name == "Grade 7"
                    // )
                    return (
                      <tr key={index}>
                        <td>
                          <Link className="" to={`../grade/${grade.sys.id}`}>
                            {grade.fields.name}
                          </Link>
                        </td>
                        <td className="text-end">
                          <Link
                            className="btn btn-primary btn-sm mx-2"
                            to={`../grade/${grade.sys.id}`}
                          >
                            {t("grados.ver")}
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Grades);
