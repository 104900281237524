import React, { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import Latex from "react-latex";
import { ContentBlockEmbeddedAsset } from "./ContentBlockEmbeddedAsset";
import { useTranslation } from "react-i18next";
import { richText, RICHTEXT_WITH_HTML } from "../../Utils/richText";

class ContentBlockParagraph extends Component {
  componentDidMount() {}

  ParagraphContent(props) {
    const { contentBlock } = props;
    return (
      <div className="my-1">{richText(contentBlock, RICHTEXT_WITH_HTML)}</div>
    );
  }

  extractParagraphContent(contentBlock, showAnswers, references, clau, t) {
    if (!contentBlock) return;

    let items = [];

    let i;

    for (i = 0; i < contentBlock.content.length; i++) {
      let text = contentBlock.content[i].value;

      let regexInput = /\*\*(.*?)\*\*/g;
      let regex_x = /\*\*(x+)\*\*/g;
      let regex_o = /\*\*(o+)\*\*/g;
      let regex_t = /\*\*(t+)\*\*/g;
      let regex_s = /\*\*(s+)\*\*/g;
      let regexAnswer = /\[\[(.*?)\]\]/gm;
      let regexLatex = /\$\$(.*?)\$\$/gm;

      let matches = [...text.matchAll(regexAnswer)];
      if (matches.length > 0) {
        let answer_class = `answer ${showAnswers ? "show" : "hide"}`;
        if (matches[0][1].includes("answerIsAnImage")) {
          text =
            '<div class="' +
            answer_class +
            '"><p>' +
            t("alumno.solucion") +
            ":</p></div>";
        } else {
          text =
            '<div class="' +
            answer_class +
            '"><p>' +
            t("alumno.solucion") +
            ":</p>" +
            text;
          for (const match of matches) {
            if (match && match[1]) {
              let answer = match[1];

              if (answer.includes("$$"))
                answer = match[1].replaceAll("$", "$$$");

              if (answer.includes("asset=")) {
                let assetId = match[1].replace("asset=", "");
                answer = renderToStaticMarkup(
                  <ContentBlockEmbeddedAsset
                    references={references}
                    key={`embeddedAsset-${assetId}`}
                    id={assetId}
                  />
                );
              }

              text = text.replace(match[0], `${answer}<br>`);
            }
          }
          text += "</div>";
          contentBlock.asHTML = true;
        }
      }

      let latexMatches = [...text.matchAll(regexLatex)];
      if (latexMatches.length > 0) {
        for (const match of latexMatches) {
          if (match && match[1]) {
            let stringToPrint = match[0];
            let notEqualSigns = [
              "\\not=",
              "\\not =",
              "\\neq",
              "\\nespace",
              "\\ne",
            ];
            for (let notEqualSign of notEqualSigns) {
              if (stringToPrint.includes(notEqualSign))
                stringToPrint = stringToPrint.replaceAll(notEqualSign, "≠");
            }
            text = text.replace(
              match[0],
              renderToStaticMarkup(
                <Latex
                  strict={"ignore"}
                  throwOnError={false}
                  trust={true}
                  errorColor={"#212529"}
                >
                  {stringToPrint.replace("$$\\\\", "$$$$")}
                </Latex>
              )
            );
          }
        }
        contentBlock.asHTML = true;
      }

      if (text.includes(`<div class="answer`)) {
        text = text.replace(" show", "");
        text = text.replace(" hide", "");

        text = text.replace(
          "answer",
          `answer ${showAnswers ? "show" : "hide"}`
        );
      }

      let match;
      while ((match = regexInput.exec(text))) {
        if (match[1].includes("x"))
          text = text.replace(regex_x, match[1].replaceAll("x", "____"));

        if (match[1].includes("o")) text = text.replace(regex_o, "o");

        if (match[1].includes("t")) text = text.replace(regex_t, "test");

        if (match[1].includes("s"))
          text = text.replace(
            regex_s,
            renderToStaticMarkup(<div className={`separator`}></div>)
          );

        if (!regexInput.global) break;
      }

      contentBlock.asHTML = true;

      contentBlock.content[i].value = text;
    }

    // for (i = 0; i < contentBlock.content.length; i++) {
    //   if (contentBlock.content[i].marks.length > 0 && contentBlock.asHTML) {
    //     let classes = "";

    //     for (let mark of contentBlock.content[i].marks) {
    //       if (mark.type === "bold") classes += "bold ";
    //     }

    //     contentBlock.content[i].value = renderToStaticMarkup(
    //       <span className={classes}>{contentBlock.content[i].value}</span>
    //     );
    //     contentBlock.content[i].marks = [];
    //   }
    // }

    items.push(
      <this.ParagraphContent contentBlock={contentBlock} key={`${clau}_p`} />
    );

    return items;
  }

  render() {
    const { contentBlock, showAnswers, references, clau, t } = this.props;
    // console.log("contentBlock", contentBlock);
    let lines = this.extractParagraphContent(
      contentBlock,
      showAnswers,
      references,
      clau,
      t
    );

    return <div className="my-0">{lines}</div>;
  }
}

function encapsulate(Children) {
  return (props) => {
    const { t } = useTranslation();

    return <Children {...props} t={t} />;
  };
}

export default encapsulate(ContentBlockParagraph);
