import React, {Component} from "react";

import "../scss/staging/App.scss";

class PublicPage extends Component{
    render() {
        const {element} = this.props;

        return (
            <div className="layout staging">
                {element}
            </div>
        );
    }
}

export default PublicPage;