import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../Utils/withRouter";
import logo from "../images/logo-JM-blau.jpg";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      language: "",
    };
  }

  componentDidMount() {
    const { i18n, t, match } = this.props;

    this.setState({ language: this.props.i18n.language });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.t !== prevProps.t) {
      this.setState({ language: this.props.i18n.language });
    }
  }

  render() {
    const { i18n, t } = this.props;

    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark bg-dark navbar-global">
          <div className="container-fluid container">
            <Link className="navbar-brand" to="../">
              <img className="imgLogo" src={logo} />
            </Link>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarCollapse"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/${this.state.language}/staging/`}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/${this.state.language}/staging/grades`}
                  >
                    {t("navbar.grades")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to={`/${this.state.language}/staging/lessons`}
                  >
                    {t("navbar.lessons")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default withRouter(Navbar);
