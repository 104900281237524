import { createRoot } from 'react-dom/client';
import App from './App'
import './i18n/config'
import {BrowserRouter as Router} from "react-router-dom";
import {CookiesProvider} from "react-cookie";

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <CookiesProvider>
        <Router basename="/">
            <App/>
        </Router>
    </CookiesProvider>);

