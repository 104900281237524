import $ from "jquery";
import { loadFromJson, randomizeSlides } from "./LessonPlayer.content";

export const refreshNavigation = (globalState) => {
  $(".slideNotesHeader .title").text(
    globalState.lesson.fields?.code + " " + globalState.lesson.fields?.name
  );

  const images = require.context("../../images", true);
  let loadImage = (imageName) => images(`./${imageName}`);

  for (const slideGroup of globalState.slideGroups) {
    if (globalState.slidePosition.current >= slideGroup.initialSlidePosition) {
      if (
        globalState.slidePosition.current <
        slideGroup.initialSlidePosition + slideGroup.slides.length
      ) {
        $(".navItem" + slideGroup.sectionPosition).addClass("current");
        $(".navItem" + slideGroup.sectionPosition).removeClass("visited");

        $(".slideNotesHeader .subtitle span").text(slideGroup.sectionTitle);

        if (globalState.slidePosition.current !== 0) {
          $(".slideNotesHeader .subtitle .icon").attr(
            "src",
            loadImage(`${slideGroup.sectionIcon}.svg`)
          );
        }
      } else {
        $(".navItem" + slideGroup.sectionPosition).removeClass("current");
        $(".navItem" + slideGroup.sectionPosition).addClass("visited");
      }
    } else {
      $(".navItem" + slideGroup.sectionPosition).removeClass("current");
      $(".navItem" + slideGroup.sectionPosition).removeClass("visited");
    }
  }

  // if($(`.slideNote${vars.currentSlidePosition}`).data("navigation") ?? true){
  //     $(".slideNotesBottomMenu").css("opacity", "0");
  //     $(".slideNotesBottomMenu").css("display", "block");
  //     $(".slideNotesBottomMenu").animate({
  //         opacity: 100
  //     }, 4000, function() {
  //         // Animation complete.
  //     });
  // }else{
  //     $(".slideNotesBottomMenu").css("opacity", "0");
  //     $(".slideNotesBottomMenu").css("display", "none");
  // }

  $(".slideNotesSidebar .slideNotesSidebarContent").text(``);

  if (globalState.slidePosition.current === 0) {
    $(".slideNotesHeader").addClass("white");
    $(".slideNotesHeader").removeClass("visible");
    $(".slideNotesBottomMenu").removeClass("visible");
    $(".slideNotesTopMenu").addClass("white");

    closeNotes();
  } else {
    $(".slideNotesHeader").removeClass("white");
    $(".slideNotesHeader").addClass("visible");
    $(".slideNotesBottomMenu").addClass("visible");
    $(".slideNotesTopMenu").removeClass("white");

    let currentSlide = globalState.slides[globalState.slidePosition.current];

    if (currentSlide) {
      let teacherNotes = currentSlide.slideBlock
        ? $(`#teachersNotes${globalState.slidePosition.current}`).html()
        : "";

      $(".slideNotesSidebar .slideNotesSidebarContent").html(`${teacherNotes}`);
    }
  }

  $(".numSlides").text(
    `${globalState.slidePosition.current}/${globalState.totalSlides}`
  );

  refreshProgressBar(globalState);
};

export const refreshProgressBar = (globalState, animate = true) => {
  let navItemPosition = $(".navItem.current .circle").position();

  if (navItemPosition == null) {
    $(".line-progress").css("width", "0px");
    $(".indicator").text("");
    return;
  }

  let position =
    navItemPosition.left + $(".navItem.current .circle").width() / 2;

  let positionInSection =
    globalState.slidePosition.current -
    $(".navItem.current .circle").data("initialslideposition") +
    1;

  let numSlidesInSection = $(".navItem.current .circle").data("sectionslides");

  let sectionPosition = $(".navItem.current .circle").data("sectionposition");

  if (
    positionInSection === 1 &&
    globalState.slidePosition.current > globalState.slidePosition.previous
  ) {
    $(".line-progress").css("width", "0px");
  }

  $(".indicator").text("");

  if (sectionPosition < globalState.navItems.length - 1) {
    let sectionWidth =
      $(`.navItem${sectionPosition + 1} .circle`).position().left -
      $(`.navItem${sectionPosition} .circle`).position().left;

    let width = Math.floor(
      (sectionWidth * positionInSection) / numSlidesInSection
    );

    $(".indicator").text(`${positionInSection}/${numSlidesInSection}`);
    $(".indicator").css(
      "left",
      `${navItemPosition.left + Math.floor(sectionWidth / 2)}px`
    );

    if (animate) {
      $(".line-progress").animate(
        {
          width: width,
          left: position + "px",
        },
        200,
        function () {
          // Animation complete.
        }
      );
    } else {
      $(".line-progress").css("width", width);
      $(".line-progress").css("left", `${position}px`);
    }
  }
};

export const goToSection = (e, globalState) => {
  let newCurrentSlidePosition = $(e.target).data("initialslideposition");
  globalState.functions.setSlidePosition({
    current: newCurrentSlidePosition,
    previous: globalState.slidePosition.current,
  });
  globalState.carousel.select(newCurrentSlidePosition);
};

export const empezar = (globalState) => {
  let newCurrentSlidePosition = 1;
  globalState.functions.setSlidePosition({
    current: newCurrentSlidePosition,
    previous: globalState.slidePosition.current,
  });
  globalState.carousel.select(newCurrentSlidePosition, false, false);
};

export const empezarAleatorio = (globalState) => {
  randomizeSlides(globalState);
};

export const empezarEstructuraPersonalizada = (globalState) => {
  loadFromJson(globalState);
};

export const nextSlide = (carousel) => {
  carousel.next();
};

export const prevSlide = (carousel) => {
  carousel.previous();
};

export const toggleFullscreen = () => {
  let elem = document.documentElement;

  if (document.fullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  } else {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }
};

export const toggleNotes = () => {
  if ($(".slideNotesSidebar").data("open")) {
    closeNotes();
  } else {
    openNotes();
  }
};

export const openNotes = () => {
  $(".slideNotesSidebar").animate(
    {
      right: 0,
    },
    200,
    function () {
      $(".slideNotesSidebar").data("open", true);
    }
  );

  $(".slideNotesTopMenu").animate(
    {
      right: $(".slideNotesSidebar").width(),
    },
    200,
    function () {}
  );
};

export const closeNotes = () => {
  $(".slideNotesSidebar").animate(
    {
      right: -$(".slideNotesSidebar").width(),
    },
    200,
    function () {
      $(".slideNotesSidebar").data("open", false);
    }
  );

  $(".slideNotesTopMenu").animate(
    {
      right: 0,
    },
    200,
    function () {}
  );
};

export const toggleOrganizer = () => {
  if ($(".slideNotesOrganizer").data("open")) {
    closeOrganizer();
  } else {
    openOrganizer();
  }
};

export const openOrganizer = () => {
  $(".slideNotesOrganizer").fadeIn(200, function () {
    $(".slideNotesOrganizer").data("open", true);
  });
};

export const closeOrganizer = () => {
  $(".slideNotesOrganizer").fadeOut(200, function () {
    $(".slideNotesOrganizer").data("open", false);
  });
};
