import React, { Component } from 'react'
import {withRouter} from "../Utils/withRouter";

class Home extends Component {
    constructor() {
        super()
    }
    componentDidMount() {

    }

    render() {
        const { i18n, t, env } = this.props;

        return (
            <div className="container-fluid my-5">
                <div className="row">
                    <div className="col-md-12">
                        <div>Idioma: { i18n.language }</div>
                        <div>Entorno: { env }</div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Home)