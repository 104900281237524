import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const LessonNavbar = (props) => {
    const { t } = useTranslation();

    const { globalState } = props;

    return (
        <nav className="navbar navbar-expand-md navbar-light bg-transparent mt-3">
            <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link className="nav-link" to={`../lessons/${globalState.grade_id}/${globalState.unit_id}/${globalState.id}/lesson-plan`}>
                            {t("navbar.guia_docente")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`../lessons/${globalState.grade_id}/${globalState.unit_id}/${globalState.id}/lesson-player`}>
                            {t("navbar.presentacion_aula")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`../lessons/${globalState.grade_id}/${globalState.unit_id}/${globalState.id}/lesson-apbook`}>
                            {t("navbar.libro_alumno")}
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default LessonNavbar;