import React from "react";
import InitialNote from "./InitialNote";
import SlideNote from "./SlideNote";
import FinalNote from "./FinalNote";

const LessonPlayerPdf = ({ globalState, slides }) => {

    if(slides.length > 0){
        return (
            <>
                {slides.map((slide) => {

                    switch(slide.type){
                        case "initialNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <InitialNote slide={slide} globalState={globalState} />
                                </div>
                            );
                        case "slideNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <SlideNote slide={slide} key={`slideNote${slide.slidePosition}`} isPdf={globalState.isPdf}
                                               setIframeSrc={globalState.functions.setIframeSrc} setIsModalOpen={globalState.functions.setIsModalOpen} />
                                </div>
                            );
                        case "finalNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <FinalNote slide={slide} globalState={globalState} />
                                </div>
                            );
                        default:
                            return <></>;
                    }

                })}
            </>
        );
    }else{
        return <></>;
    }
};

export default LessonPlayerPdf;