import { gql } from "@apollo/client";

export const getLessonPlan = (lessonId, language) => {
  return gql`
        ${defineTeachingBlockFragment(language)}
        ${defineMMMBlockFragment(language)}
        query getLessonPlan{
            lessons(id: "${lessonId}", locale:"${language}"){
                sys{
                    id
                }
                idName
                code
                name
                apBookPage
                objective{
                    json
                }
                previousKnowledge{
                    json
                }
                teachingBlocksCollection(limit:10){
                    items{
                        ...TeachingBlock
                    }
                }
                mmmBlocksCollection(limit: 10){
                    items{
                        ...MMMBlock
                    }
                }
                trExtensionBlocksCollection(limit: 10){
                    items{
                        ...on TeachingBlocks{
                            ...TeachingBlock
                        }
                        ...on MmmBlocks{
                            ...MMMBlock
                        }
                    }
                }
            }
        }
    `;
};

const defineTeachingBlockFragment = (language) => gql`
  fragment TeachingBlock on TeachingBlocks {
    sys {
      id
    }
    title
    duration
    slideText1 {
      json
    }
    slideText2 {
      json
    }
    slideText3 {
      json
    }
    slideText4 {
      json
    }
    slideText5 {
      json
    }
    slideText6 {
      json
    }
    slideText7 {
      json
    }
    teachersNotes1 {
      json
    }
    teachersNotes2 {
      json
    }
    teachersNotes3 {
      json
    }
    teachersNotes4 {
      json
    }
    teachersNotes5 {
      json
    }
    teachersNotes6 {
      json
    }
    teachersNotes7 {
      json
    }
    competence {
      sys {
        id
      }
      name
    }
    vocabularyCollection(limit: 10) {
      items {
        ... on Vocabulary {
          sys {
            id
          }
          name
        }
      }
    }
    classroomMaterialsCollection(limit: 10) {
      items {
        sys {
          id
        }
        name
      }
    }
    manipulativesCollection(limit: 10) {
      items {
        sys {
          id
        }
        ... on Manipulatives {
          name
          linkPdf
        }
      }
    }
    standardsCollection(limit: 10) {
      items {
        sys {
          id
        }
        idName
        code
      }
    }
  }
`;

const defineMMMBlockFragment = (language) => gql`
  fragment MMMBlock on MmmBlocks {
    sys {
      id
    }
    objective {
      json
    }
    slideText1 {
      json
    }
    slideText2 {
      json
    }
    slideText3 {
      json
    }
    slideText4 {
      json
    }
    teachersNotes1 {
      json
    }
    teachersNotes2 {
      json
    }
    teachersNotes3 {
      json
    }
    teachersNotes4 {
      json
    }
    duration
    vocabularyCollection(limit: 10) {
      items {
        sys {
          id
        }
        name
      }
    }
    standardsCollection(limit: 10) {
      items {
        sys {
          id
        }
        idName
        code
      }
    }
    manipulativesCollection(limit: 10) {
      items {
        sys {
          id
        }
        ... on Manipulatives {
          name
          linkPdf
        }
      }
    }
    competence {
      sys {
        id
      }
      name
    }
  }
`;
