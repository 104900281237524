import React, { useEffect} from "react";
import {renderKatex} from "../../Utils/renderKatex";
import {richText, RICHTEXT_WITH_HTML} from "../../Utils/richText";

const ContentBlockOrderedList = (props) => {
  useEffect(() => {
    renderKatex(document.body)
  })
  const { contentBlock } = props;

  return (
    <div className="ordered-list">
      {richText(contentBlock, RICHTEXT_WITH_HTML)}
    </div>
  );
};

export default ContentBlockOrderedList;
