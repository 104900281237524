import React from "react";
import Flickity from "react-flickity-component";
import InitialNote from "./InitialNote";
import SlideNote from "./SlideNote";
import FinalNote from "./FinalNote";

const LessonPlayerSlider = ({ globalState, slides }) => {

    const flickityOptions = {
        initialIndex: 0,
        pageDots: false,
        prevNextButtons: false,
        draggable: false
    }

    const setCarousel = (newCarousel) => {
        if(globalState.carousel == null && !globalState.carouselLoaded) {
            globalState.functions.setCarousel(newCarousel);
        }
    }

    if(slides.length > 0){
        return (
            <Flickity
                className={'carousel'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={true}
                reloadOnUpdate={false}
                static={false}
                flickityRef={(flickity) => { setCarousel(flickity) }}
            >

                {slides.map((slide) => {

                    switch(slide.type){
                        case "initialNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <InitialNote slide={slide} globalState={globalState} />
                                </div>
                            );
                        case "slideNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <SlideNote slide={slide} key={`slideNote${slide.slidePosition}`} isPdf={globalState.isPdf}
                                               setIframeSrc={globalState.functions.setIframeSrc} setIsModalOpen={globalState.functions.setIsModalOpen} />
                                </div>
                            );
                        case "finalNote":
                            return(
                                <div className={'slide'} key={`slide${slide.slidePosition}`}>
                                    <FinalNote slide={slide} globalState={globalState} />
                                </div>
                            );

                        default:
                            return <div></div>;
                    }

                })}
            </Flickity>
        );
    }else{
        return <div></div>;
    }
};

export default LessonPlayerSlider;