import React from "react";
import ContentBlockParagraph from "./ContentBlockParagraph";
import { ContentBlockEmbeddedAsset } from "./ContentBlockEmbeddedAsset";
import ReviewBlock from "./ReviewBlock";
import StatementBlock from "./StatementBlock";
import ContentBlockTable from "./ContentBlockTable";
import ContentBlockOrderedList from "./ContentBlockOrderedList";
import ContentBlockUnorderedList from "./ContentBlockUnorderedList";
import { ExerciseBlock1 } from "./Exercises/ExerciseBlock1";
import { ExerciseBlock2 } from "./Exercises/ExerciseBlock2";
import { ExerciseBlock3 } from "./Exercises/ExerciseBlock3";
import { ExerciseBlock4 } from "./Exercises/ExerciseBlock4";
import { ExerciseBlock5 } from "./Exercises/ExerciseBlock5";
import { ExerciseBlock6 } from "./Exercises/ExerciseBlock6";
import { ExerciseBlock7 } from "./Exercises/ExerciseBlock7";
import { ExerciseBlock8 } from "./Exercises/ExerciseBlock8";
import { ExerciseBlock9 } from "./Exercises/ExerciseBlock9";
import { ExerciseBlock10 } from "./Exercises/ExerciseBlock10";
import { ExerciseBlock12 } from "./Exercises/ExerciseBlock12";
import { ExerciseBlock11 } from "./Exercises/ExerciseBlock11";

export const getExerciseBlockToShow = (
  exerciseBlock,
  showAnswers,
  isPdfEnv = false,
  setIsModalOpen = () => {},
  setIframeSrc = () => {}
) => {
  switch (exerciseBlock.fields.template) {
    case 1:
      return (
        <ExerciseBlock1
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 2:
      return (
        <ExerciseBlock2
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 3:
      return (
        <ExerciseBlock3
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 4:
      return (
        <ExerciseBlock4
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 5:
      return (
        <ExerciseBlock5
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 6:
      return (
        <ExerciseBlock6
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 7:
      return (
        <ExerciseBlock7
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 8:
      return (
        <ExerciseBlock8
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 9:
      return (
        <ExerciseBlock9
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 10:
      return (
        <ExerciseBlock10
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 11:
      return (
        <ExerciseBlock11
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    case 12:
      return (
        <ExerciseBlock12
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
    default:
      return (
        <ExerciseBlock1
          exerciseBlock={exerciseBlock}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIsModalOpen={setIsModalOpen}
          setIframeSrc={setIframeSrc}
        />
      );
  }
};

export const showExerciseBlocks = (
  exerciseBlocks,
  showAnswers,
  showMetaInfo,
  isPdfEnv = false,
  setIsModalOpen = () => {},
  setIframeSrc = () => {}
) => {
  return (
    <>
      {exerciseBlocks?.map((exerciseBlock, index) => {
        let exerciseBlockToShow = getExerciseBlockToShow(
          exerciseBlock,
          showAnswers,
          isPdfEnv,
          setIsModalOpen,
          setIframeSrc
        );

        if (exerciseBlockToShow) {
          return (
            <div className="ejercicio" key={`${exerciseBlock.sys.id}-${index}`}>
              <p className={`mb-3 metainfo ${showMetaInfo ? "show" : "hide"}`}>
                <span className="me-2 text-end badge badge-primary p-1 rounded white">
                  Exercise block #{index + 1}
                </span>
                {exerciseBlock.sys.idName} (template {exerciseBlock.template})
              </p>

              <ReviewBlock
                exerciseBlock={exerciseBlock}
                isPdfEnv={isPdfEnv}
                setIframeSrc={setIframeSrc}
                setIsModalOpen={setIsModalOpen}
              />

              <div className="exerciseBlockWrapper">
                <StatementBlock
                  exerciseIndex={index + 1}
                  exerciseBlock={exerciseBlock}
                />

                {exerciseBlockToShow}
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export const getContentBlockHelperValues = (
  contentBlock,
  indexb,
  contentBlocks
) => {
  let isAbsolute = false;
  if (
    contentBlock.nodeType === "paragraph" &&
    contentBlocks[indexb + 1]?.nodeType === "embedded-asset-block" &&
    indexb === 0
  ) {
    isAbsolute = true;
  }
  let isAnswer = false;
  for (let i = 0; i < contentBlock.content.length; i++) {
    let text = contentBlock.content[i].value;
    let regexAnswer = /\[\[(.*?)\]\]/gm;
    let matches = [];
    if (!!text) {
      matches = [...text.matchAll(regexAnswer)];
    }
    if (matches.length > 0) {
      isAnswer = true;
      break;
    }
  }
  let isEmpty = false;
  if (contentBlock.content[0]?.value?.trim().length === 0) {
    isEmpty = true;
  }
  return { isAbsolute, isAnswer, isEmpty };
};

export function getApartados(contenido) {
  let apartados = [];
  let apartado = [];
  let answerIsAnImage = false;
  if (contenido) {
    // console.log("contenido", contenido);
    for (let content of JSON.parse(JSON.stringify(contenido))?.content) {
      content.viewAsAnswer = answerIsAnImage;
      if (content.nodeType === "hr") {
        apartados.push(apartado);
        apartado = [];
      } else {
        apartado.push(content);
      }

      if (content.nodeType === "paragraph") {
        answerIsAnImage =
          content.content?.filter((c) =>
            c.value?.includes("[[answerIsAnImage]]")
          ).length > 0;
      }
    }
    apartados.push(apartado);
  }
  // console.log("apartados", apartados);
  return apartados;
}

export function getContentBlockToShow(
  contentBlock,
  showAnswers = false,
  key,
  isPdfEnv = false,
  setIsModalOpen = () => {},
  setIframeSrc = () => {}
) {
  let contentBlockToShow = null;
  switch (contentBlock.nodeType) {
    case "paragraph":
      contentBlockToShow = (
        <ContentBlockParagraph
          contentBlock={contentBlock}
          showAnswers={showAnswers}
          key={`paragraph-${key}`}
        />
      );
      break;
    case "embedded-asset-block":
      contentBlockToShow = (
        <ContentBlockEmbeddedAsset
          key={`embeddedAsset-${key}`}
          id={contentBlock.data?.target?.sys?.id || "no_id"}
          viewAsAnswer={contentBlock.viewAsAnswer}
          showAnswers={showAnswers}
          isPdfEnv={isPdfEnv}
          setIframeSrc={setIframeSrc}
          setIsModalOpen={setIsModalOpen}
        />
      );
      break;
    case "table":
      contentBlockToShow = <ContentBlockTable contentBlock={contentBlock} />;
      break;
    case "ordered-list":
      contentBlockToShow = (
        <ContentBlockOrderedList contentBlock={contentBlock} />
      );
      break;
    case "unordered-list":
      contentBlockToShow = (
        <ContentBlockUnorderedList contentBlock={contentBlock} />
      );
      break;
    default:
      contentBlock = null;
      break;
  }
  return contentBlockToShow;
}
