import {nextSlide, prevSlide, toggleOrganizer} from "./LessonPlayer.ui";
import React from "react";
import NavItem from "./NavItem";
import {exportSlides} from "./LessonPlayer.content";


const LessonPlayerBottomMenu = ({globalState, carousel, navItems, slidePosition, totalSlides}) => {

    return <div className="slideNotesBottomMenu">
        <div className="navigation">
            <div className="lines">
                <div className="line-bg"></div>
                <div className="line-progress"></div>
            </div>
            <div className="navItems">

                {navItems.map((navItem, index) => {

                    return(
                        <NavItem
                            navItem={navItem}
                            globalState={globalState}
                            key={`navItem${index}`}
                        />
                    );

                })}

            </div>
            <div className="indicator"></div>
        </div>

        <div className="arrows">
            <button onClick={() => { prevSlide(globalState.carousel); }} className="leftArrow">
                <svg
                    width="16"
                    height="29"
                    viewBox="0 0 16 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14 27L2.66483 15.1925C2.29335 14.8056 2.29335 14.1944 2.66483 13.8075L14 2"
                        stroke="#0069B7"
                        strokeWidth="3"
                    />
                </svg>
            </button>

            <div className="numSlides">
                {slidePosition.current}/{totalSlides}
            </div>

            <button onClick={() => { nextSlide(globalState.carousel); }} className="rightArrow">
                <svg
                    width="16"
                    height="29"
                    viewBox="0 0 16 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2 2L13.3352 13.8075C13.7067 14.1944 13.7067 14.8056 13.3352 15.1925L2 27"
                        stroke="#0069B7"
                        strokeWidth="3"
                    />
                </svg>
            </button>
        </div>

        <div className="bottomLeft">

        </div>


        <div className="copyright">Copyright © 2023 JUMP Math</div>
    </div>;
}

export default LessonPlayerBottomMenu;