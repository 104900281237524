import React from "react";
import {getApartados, getContentBlockHelperValues, getContentBlockToShow} from "../ExerciseBlocks";

export const ExerciseBlock8 = (props) => {
    const { exerciseBlock, showAnswers, isPdfEnv, setIframeSrc, setIsModalOpen } =
        props;
    return (
        <section className="row exerciseBlock exerciseBlock8">
            {getApartados(exerciseBlock.fields.content).map((apartado, index) => {
                return (
                    <div className="col-3" key={`${exerciseBlock.sys.id}-${index}`}>
                        <div className="container">
                            <div className="row card">
                                {apartado.map((contentBlock, indexb, contentBlocks) => {
                                    const { isAbsolute, isAnswer, isEmpty } =
                                        getContentBlockHelperValues(
                                            contentBlock,
                                            indexb,
                                            contentBlocks
                                        );
                                    return (
                                        <div
                                            className={`col-12 ${isAbsolute ? "is-absolute" : ""} ${
                                                (isAnswer && !showAnswers) || isEmpty ? "hide" : ""
                                            }`}
                                            key={`${exerciseBlock.sys.id}-${index}-${indexb}`}
                                        >
                                            {getContentBlockToShow(
                                                contentBlock,
                                                showAnswers,
                                                `${exerciseBlock.sys.id}-${index}-${indexb}`,
                                                isPdfEnv,
                                                setIsModalOpen,
                                                setIframeSrc
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};