import {toggleOrganizer} from "./LessonPlayer.ui";
import $ from "jquery";
import 'jquery-ui-sortable-npm';
import {exportSlides} from "./LessonPlayer.content";
import React, {useState} from "react";

const LessonPlayerOrganizer = ({ globalState, slides, slideGroups }) => {

    const [customSlideGroups, setCustomSlideGroups] = useState(slideGroups)

    const refreshSlidesStructure = () => {

        let _customSlideGroups = []
        $(".section").each(function(index) {
            let section = $(this)

            let _slideGroup = {
                id: section.data('id'),
                slides: []
            }

            section.find(".slide:not(.first)").each(function(index) {
                let slide = $(this)
                _slideGroup.slides.push(slide.data('position'))
            });

            _customSlideGroups.push(_slideGroup)
        });

        setCustomSlideGroups(_customSlideGroups)
        console.log(_customSlideGroups)
    };

    $(".sections").sortable({
        placeholder: 'slide-placeholder',
        handle: ".slide.first",
        items: "li:not(.sorting-disabled)",
        axis: "y",
        revert: 150,
        start: function(e, ui){
            let placeholderHeight = ui.item.outerHeight();
            ui.placeholder.height(placeholderHeight + 15);
            $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder);
        },
        change: function(event, ui) {

            ui.placeholder.stop().height(0).animate({
                height: ui.item.outerHeight() + 15
            }, 300);

            let placeholderAnimatorHeight = parseInt($(".slide-placeholder-animator").attr("data-height"));

            $(".slide-placeholder-animator").stop().height(placeholderAnimatorHeight + 15).animate({
                height: 0
            }, 300,
            function() {
                $(this).remove();
                let placeholderHeight = ui.item.outerHeight();
                $('<div class="slide-placeholder-animator" data-height="' + placeholderHeight + '"></div>').insertAfter(ui.placeholder);
            });

        },
        stop: function(e, ui) {
            $(".slide-placeholder-animator").remove();
        },
        update: function(event, ui){
            refreshSlidesStructure();
        }
    });

    $(".section:not(.sorting-disabled)").each(function(index) {

        $(this).sortable({
            items: ".slide:not(.sorting-disabled)",
            axis: "x",
            revert: 150,
            start: function(e, ui){

            },
            change: function(event, ui) {

            },
            stop: function(e, ui) {
            },
            update: function(event, ui){
                refreshSlidesStructure();
            }
        });

    });

    return <div className="slideNotesOrganizer">
        <div className="slideNotesOrganizerHeader">
            <div>Organizador de slides</div>
        </div>
        <div className="slideNotesOrganizerContent">

            <ul className="sections">
                {slideGroups.map((slideGroup) => {
                    let sortingEnabled = slideGroup.id != "initialSection"
                        && slideGroup.id != "mmmBlocksSection" && slideGroup.id != "finalSection";
                    return(
                        <li className={`section ${sortingEnabled ? '' : 'sorting-disabled'} `} id={`section${slideGroup.sectionPosition}`}
                            data-position={`${slideGroup.sectionPosition}`} data-id={`${slideGroup.id}`} key={`slideGroup${slideGroup.sectionPosition}`}>
                            <div className="slidesWrapper">
                                <div className="slides">
                                    <div className="slide first sorting-disabled">
                                        { slideGroup.sectionPosition } - { slideGroup.title }
                                    </div>
                                    {slideGroup.slides.map((slide) => {
                                        return(
                                            <div className={`slide ${sortingEnabled ? '' : 'sorting-disabled'} `} data-position={`${slide.index}`}
                                                 key={`slide${slideGroup.sectionPosition}${slide.index}`}>
                                                { slide.slidePosition }
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>

        </div>
        <div className="slideNotesOrganizerFooter">
            <div className="bottomLeft">
                <button onClick={() => { toggleOrganizer() }} className="organize">Cerrar sin guardar</button>
                <button onClick={() => { exportSlides(globalState) }} className="export">Guardar cambios</button>
            </div>
        </div>
    </div>;
}

export default LessonPlayerOrganizer;