import React from "react";
import { getApartados, getContentBlockToShow } from "./ExerciseBlocks";

const ReviewBlock = (props) => {
  if (props.exerciseBlock.fields.review != null) {
    let apartados = getApartados(props.exerciseBlock.fields.review);

    let className = "col-12";
    switch (apartados.length) {
      case 1:
        className = "col-12";
        break;
      case 2:
        className = "col-6";
        break;
      case 3:
        className = "col-4";
        break;
      case 4:
        className = "col-3";
        break;
      default:
        className = "col-6";
        break;
    }

    return (
      <div
        className="row reviewBlock mb-4"
        role="alert"
        key={`reviews-${props.exerciseBlock.sys.id}`}
      >
        {apartados.map((apartado, index) => {
          return (
            <div
              className={className}
              key={`review-${props.exerciseBlock.sys.id}-${index}`}
            >
              {apartado.map((contentBlock, indexb) => {
                return getContentBlockToShow(
                  contentBlock,
                  false,
                  `review-${props.exerciseBlock.sys.id}-${index}-${indexb}`,
                  props.isPdfEnv,
                  props.setIsModalOpen,
                  props.setIframeSrc
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else return "";
};

export default ReviewBlock;
