import {getContentBlockClasses} from "./LessonPlayer.functions";
import {useLayoutEffect} from "react";
import {renderKatex} from "../../Utils/renderKatex";
import {getApartados, getContentBlockToShow} from "../UI/ExerciseBlocks";

const SlideNote = (props) => {
    const { slide, isPdf, setIframeSrc, setIsModalOpen } = props;

    let slideText = {
        apartados: getApartados(slide.slideBlock.fields[`slideText${slide.index}`])
    };

    let teachersNotes = {
        apartados: getApartados(slide.slideBlock.fields[`teachersNotes${slide.index}`])
    };

    useLayoutEffect(() => {
        renderKatex(document.body);
    });

    function getClassNameByPosition(position, totalApartados) {
        switch (totalApartados) {
            case 1:
                return "col-12";
            case 2:
                return "col-6";
            default:
                return position === 0 ? "col-12" : "col-6";
        }
    }

    return (
        <div className={`slideNote slideNote${slide.slidePosition}`}
             data-position={slide.slidePosition}>
            <h1 className="slide-section-title">{slide.slideGroup.sectionTitle}</h1>
            <div className="slideNoteWrapper">
                <div className="slideNoteContent">
                    <div className="row">
                        {slideText.apartados.map((apartado, indexA) => {
                            return (
                                <div className={getClassNameByPosition(indexA, slideText.apartados.length)}
                                    key={`slideNote${slide.slidePosition}-slideText${indexA}`}>

                                    {apartado.map((contentBlock, indexb, contentBlocks) => {
                                        const contentBlockClasses = getContentBlockClasses(
                                            contentBlock,
                                            indexb,
                                            contentBlocks
                                        );
                                        return (
                                            <div className={contentBlockClasses} key={`slideNote${slide.slidePosition}-slideText${indexA}-${indexb}`}>
                                                {getContentBlockToShow(
                                                    contentBlock,
                                                    false,
                                                    `slideNote${slide.slidePosition}-slideText${indexA}-${indexb}-c`,
                                                    isPdf,
                                                    setIsModalOpen,
                                                    setIframeSrc
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div id={`teachersNotes${slide.slidePosition}`} style={{ display: "none" }}>
                {teachersNotes.apartados.map((apartado, indexA) => {
                    return (
                        <div className={getClassNameByPosition(indexA, teachersNotes.apartados.length)}
                            key={`slideNote${slide.slidePosition}-teachersNotes${indexA}`}>
                            {apartado.map((contentBlock, indexb) => {
                                return getContentBlockToShow(
                                    contentBlock,
                                    false,
                                    `slideNote${slide.slidePosition}-teachersNotes${indexA}-${indexb}`
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SlideNote;