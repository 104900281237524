import React from "react";
import {getApartados, getContentBlockHelperValues, getContentBlockToShow} from "../ExerciseBlocks";

export const ExerciseBlock10 = (props) => {
    const { exerciseBlock, showAnswers, isPdfEnv, setIframeSrc, setIsModalOpen } =
        props;

    let apartados = getApartados(exerciseBlock.fields.content);

    let first_apartado = apartados.shift();

    return (
        <section className="row exerciseBlock exerciseBlock10">
            {first_apartado != null ? (
                <div className="col-12 ms-3">
                    {first_apartado.map((contentBlock, index) => {
                        return getContentBlockToShow(
                            contentBlock,
                            showAnswers,
                            `${exerciseBlock.sys.id}-${index}-efd`,
                            isPdfEnv,
                            setIsModalOpen,
                            setIframeSrc
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
            {apartados.map((apartado, index) => {
                return (
                    <div className="col-6" key={`${exerciseBlock.sys.id}-${index}`}>
                        <div className="row card">
                            {apartado.map((contentBlock, indexb, contentBlocks) => {
                                const { isAbsolute, isAnswer, isEmpty } =
                                    getContentBlockHelperValues(
                                        contentBlock,
                                        indexb,
                                        contentBlocks
                                    );
                                return (
                                    <div
                                        className={`col-12 ${isAbsolute ? "is-absolute" : ""} ${
                                            (isAnswer && !showAnswers) || isEmpty ? "hide" : ""
                                        }`}
                                        key={`${exerciseBlock.sys.id}-${index}-${indexb}`}
                                    >
                                        {getContentBlockToShow(
                                            contentBlock,
                                            showAnswers,
                                            `${exerciseBlock.sys.id}-${index}-${indexb}`,
                                            isPdfEnv,
                                            setIsModalOpen,
                                            setIframeSrc
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </section>
    );
};