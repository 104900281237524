import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DeliveryClient } from "../Clients";
import { createContent } from "./LessonPlayer.content";

import React, { useEffect, useState } from "react";
import { initCarousel } from "./LessonPlayer.content";
import LessonPlayerTopMenu from "./LessonPlayerTopMenu";
import LessonPlayerSidebar from "./LessonPlayerSidebar";
import LessonPlayerHeader from "./LessonPlayerHeader";
import LessonPlayerBottomMenu from "./LessonPlayerBottomMenu";
import "flickity/css/flickity.css";
import { refreshNavigation } from "./LessonPlayer.ui";
import LessonPlayerSlider from "./LessonPlayerSlider";
import LessonPlayerOrganizer from "./LessonPlayerOrganizer";
import Modal from "react-modal";
import LessonPlayerPdf from "./LessonPlayerPdf";

Modal.setAppElement("#root");

const LessonPlayer = (props) => {
  let match = { params: useParams() };
  let { i18n } = useTranslation();

  const [lesson, setLesson] = useState({});
  const [carousel, setCarousel] = useState(null);
  const [carouselLoaded, setCarouselLoaded] = useState(false);
  const [slides, setSlides] = useState([]);
  const [navItems, setNavItems] = useState([]);
  const [initialSlideGroups, setInitialSlideGroups] = useState([]);
  const [slideGroups, setSlideGroups] = useState([]);
  const [totalSlides, setTotalSlides] = useState(0);
  const [slidePosition, setSlidePosition] = useState({
    current: 0,
    previous: 0,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const globalState = {
    id: match.params.id,
    grade_id: match.params.grade_id,
    unit_id: match.params.unit_id,
    lesson: lesson,
    isPublic: props.env === "public",
    isPdf: props.env === "pdf",
    navItems: navItems,
    initialSlideGroups: initialSlideGroups,
    slideGroups: slideGroups,
    totalSlides: totalSlides,
    slidePosition: slidePosition,
    carousel: carousel,
    carouselLoaded: carouselLoaded,
    slides: slides,
    isModalOpen: isModalOpen,
    iframeSrc: iframeSrc,
    jsonToLoad:
      '[{"id":"initialSection","slides":[1]},{"id":"mmmBlocksSection","slides":[1]},{"id":"6TsdP3ln7HHTZsaCYHUnJ4","slides":[3,1,2,4]},{"id":"3SfiQW7P9zpSNsbskMmx9d","slides":[5,4,2,1,3]},{"id":"1z6u1UE9yQGC2b7gpEwwty","slides":[4,1,2,5,3]},{"id":"2POL4Qr3kmlzlVVpfLxo82","slides":[4,1,2,3]},{"id":"2F58Vj0VQ2oDkeUWYjbRGw","slides":[4,1,2,3,5]},{"id":"finalSection","slides":[1]}]',
    functions: {
      setLesson: setLesson,
      setCarousel: setCarousel,
      setCarouselLoaded: setCarouselLoaded,
      setSlides: setSlides,
      setNavItems: setNavItems,
      setInitialSlideGroups: setInitialSlideGroups,
      setSlideGroups: setSlideGroups,
      setTotalSlides: setTotalSlides,
      setSlidePosition: setSlidePosition,
      setIsModalOpen: setIsModalOpen,
      setIframeSrc: setIframeSrc,
    },
  };

  useEffect(() => {
    if (Object.keys(lesson).length != 0) {
      createContent(globalState, i18n);
    }
  }, [lesson]);

  useEffect(() => {
    let _slides = slideGroups.map((slideGroup) => slideGroup.slides).flat();
    setSlides(_slides);
    setTotalSlides(_slides.length - 1);

    let _navItems = [];
    for (let slideGroup of slideGroups) {
      if (slideGroup.type != "initialSection") {
        _navItems.push({
          slideGroup: slideGroup,
        });
      }
    }
    setNavItems(_navItems);
  }, [slideGroups]);

  useEffect(() => {
    refreshNavigation(globalState);
  }, [slidePosition]);

  useEffect(() => {
    if (globalState.carousel != null && !globalState.carouselLoaded) {
      initCarousel(globalState);
      setCarouselLoaded(true);
    }
  }, [carousel]);

  if (Object.keys(globalState.lesson).length === 0) {
    DeliveryClient.getOrCreate()
      .getEntry(globalState.id)
      .then((entry) => {
        setLesson(entry);
      });
  }

  if (globalState.isPdf) {
    return (
      <>
        <div className="lesson aula">
          <LessonPlayerPdf globalState={globalState} slides={slides} />
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          <iframe
            title="setImagesApp"
            src={iframeSrc}
            style={{
              minWidth: "100%",
              minHeight: "99%",
            }}
          />
        </Modal>
      </>
    );
  } else {
    return (
      <div className="lesson aula">
        <div className="slideNotesContainer">
          <div className="slideNotes">
            <LessonPlayerSlider globalState={globalState} slides={slides} />
          </div>

          <LessonPlayerHeader />

          <LessonPlayerBottomMenu
            globalState={globalState}
            navItems={navItems}
            totalSlides={totalSlides}
            slidePosition={slidePosition}
          />
        </div>

        <LessonPlayerTopMenu />

        <LessonPlayerSidebar />

        <LessonPlayerOrganizer
          globalState={globalState}
          slides={slides}
          slideGroups={slideGroups}
        />

        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        >
          <iframe
            title="setImagesApp"
            src={iframeSrc}
            style={{
              minWidth: "100%",
              minHeight: "99%",
            }}
          />
        </Modal>
      </div>
    );
  }
};

export default LessonPlayer;
