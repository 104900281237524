
const LessonPlayerHeader = ({}) => {
    return <div className="slideNotesHeader">
        <div className="title">&nbsp;</div>
        <div className="subtitle">
            <img
                className={`icon`}
                src="/assets/images/icon_calculo_mental.svg"
                alt=""
            />
            <span></span>
        </div>
    </div>;
}

export default LessonPlayerHeader;