
const FinalNote = ({ slide, globalState }) => {

    return (
        <div className={`slideNote slideNote${slide.slidePosition}`}
             data-position={slide.slidePosition} key={`finalNote`}>
            <h1 className="slide-section-title">{slide.slideGroup.sectionTitle}</h1>
            <div className="slideNoteWrapper">
                <div className="slideNoteContent">
                    <h5>{globalState.lesson.fields.apBookPage}</h5>
                    <h2 style={{ display: "none" }}>adding css</h2>
                </div>
            </div>
        </div>
    );
};

export default FinalNote;