import React, { Component } from "react";
import { withRouter } from "../../Utils/withRouter";
import { gql, useQuery } from "@apollo/client";
import { LessonsListFragment } from "./LessonsListFragment";
import { GraphQLClient } from "../Clients";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Lessons = (props) => {
  let match = { params: useParams() };
  let { i18n } = useTranslation();

  const LessonsList = (props) => {
    const { t, i18n, env } = props;

    const GET_LESSONS = gql`
      query getLessons{
        lessonsCollection(locale:"${i18n.language}"){
          items{
            sys{
              id
            }
            name
            idName
            slug
            cumulativeActivitiesCollection(limit:1){
              items{
                sys{
                  id
                }
              }
            }
            teachingBlocksCollection(limit:1){
              items{
                sys{
                  id
                }
              }
            }
            linkedFrom(allowedLocales: ["en-CA"]){
              unitsCollection(limit:1){
                items{
                  sys{
                    id
                  }
                  linkedFrom(allowedLocales: ["en-CA"]){
                    gradesCollection(limit:1){
                      items{
                        sys{
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { loading, error, data } = useQuery(GET_LESSONS, {
      client: GraphQLClient.getOrCreate(
        process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "dev"
      ),
      errorPolicy: "ignore",
    });

    if (loading) return <p>Loading...</p>;

    // TODO: Handle error
    if (error) return <p>Error :(</p>;

    return loading && !data ? (
      <></>
    ) : (
      <LessonsListFragment
        items={data.lessonsCollection.items}
        t={t}
        i18n={i18n}
        env={env}
      />
    );
  };

  return (
    <div className={`lessons`}>
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12">
            <div className="text-center">
              <h2>{i18n.t("navbar.lessons")}</h2>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <LessonsList t={i18n.t} i18n={i18n} env={props.env} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
