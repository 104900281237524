import {useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useLayoutEffect, useState} from "react";
import LessonNavbar from "../LessonNavbar";
import {DeliveryClient} from "../Clients";
import {showExerciseBlocks} from "../UI/ExerciseBlocks";
import {renderKatex} from "../../Utils/renderKatex";
import Modal from "react-modal";

Modal.setAppElement("#root");

const LessonAPBook = (props) => {
    let match = { params: useParams() };
    let { i18n } = useTranslation();
    let [searchParams] = useSearchParams();

    const [lesson, setLesson] = useState({})
    const [unit, setUnit] = useState({})
    const [grade, setGrade] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [iframeSrc, setIframeSrc] = useState("");

    const isPublic = props.env === "public";
    const isPdf = props.env === "pdf";

    const showOnlyTheContent = isPublic || isPdf;

    let showAnswers = searchParams.get("answers") != null;
    let showMetaInfo = searchParams.get("meta") != null;

    let lessonNumber = "x.x";

    let globalState = {
        id: match.params.id,
        grade_id: match.params.grade_id,
        unit_id: match.params.unit_id,
        lesson: lesson,
        functions: {
            setLesson: setLesson
        }
    }

    useLayoutEffect(() => {
        renderKatex(document.body);
    });

    function processData() {

    }

    useEffect(() => {
        if(Object.keys(lesson).length != 0){
            processData();
        }
    }, [lesson]);

    if(Object.keys(globalState.lesson).length === 0) {
        DeliveryClient.getOrCreate()
            .getEntry(globalState.id)
            .then((entry) => {
                setLesson(entry)
            });

        DeliveryClient.getOrCreate()
            .getEntry(globalState.grade_id)
            .then((grade) => {
                setGrade(grade)
            });

        DeliveryClient.getOrCreate()
            .getEntry(globalState.unit_id)
            .then((unit) => {
                setUnit(unit)
            });
    }

    const Ejercicios = (props) => {
        if (lesson?.fields?.apExerciseBlocks?.length > 0) {
            return (
                <section className="ejercicios my-3" key={`ejercicios`}>
                    {showExerciseBlocks(
                        lesson.fields.apExerciseBlocks,
                        showAnswers,
                        showMetaInfo,
                        isPdf,
                        setIsModalOpen,
                        setIframeSrc
                    )}
                </section>
            );
        } else {
            return <section className="ejercicios my-3" key={`ejercicios`}></section>;

        }
    };

    const Ampliacion = (props) => {
        if (lesson?.fields?.apExtensionBlocks?.length > 0){
            return (
                <section className="consolidacion my-3" key={`consolidacion`}>
                    <h5>
                        <strong>{i18n.t("alumno.ampliacion")}</strong>
                    </h5>
                    {showExerciseBlocks(
                        lesson.fields.apExtensionBlocks,
                        showAnswers,
                        showMetaInfo
                    )}
                </section>
            );
        } else {
            return (
                <section className="consolidacion" key={`consolidacion`}></section>
            );
        }
    };

    const Consolidacion = (props) => {
        if (lesson?.fields?.apStrengtheningBlocks?.length > 0) {
            return (
                <section className="consolidacion my-3" key={`consolidacion`}>
                    <h5>
                        <strong>{i18n.t("alumno.consolidacion")}</strong>
                    </h5>
                    {showExerciseBlocks(
                        lesson.fields.apStrengtheningBlocks,
                        showAnswers,
                        showMetaInfo
                    )}
                </section>
            );
        } else {
            return (
                <section className="consolidacion" key={`consolidacion`}></section>
            );
        }
    };

    return (
        <div className="container lesson alumno">

            {showOnlyTheContent ? null : (
                <div className="row">
                    <div className="col-12">
                        <LessonNavbar globalState={globalState} />
                    </div>
                </div>
            )}

            <div className="row mt-5">
                <div className="col-12 lesson-title-container">
                    <span className="lesson-number">{lessonNumber}</span>
                    <h2 className="h2 lesson-title">{lesson?.fields?.name}</h2>
                    <h2 className="h2 unit-title" style={{ display: "none" }}>
                        {unit?.fields?.name}
                    </h2>
                    <h2 className="h2 grade-title" style={{ display: "none" }}>
                        {grade?.fields?.name}
                    </h2>
                    <h2 className="h2 copyright-title" style={{ display: "none" }}>
                        {i18n.t("alumno.copyright")}
                    </h2>
                    <h2 style={{ display: "none" }}>adding css</h2>
                </div>
            </div>

            <Ejercicios
                isPdfEnv={isPdf}
                setIsModalOpen={setIsModalOpen}
                setIframeSrc={setIframeSrc}
            />

            <Ampliacion />

            <Consolidacion />

            <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
                <iframe
                    title="setImagesApp"
                    src={iframeSrc}
                    style={{
                        minWidth: "100%",
                        minHeight: "99%",
                    }}
                />
            </Modal>
        </div>
    );
}

export default LessonAPBook;