import React from "react";
import {getApartados, getContentBlockHelperValues, getContentBlockToShow} from "../ExerciseBlocks";

export const ExerciseBlock12 = (props) => {
    const { exerciseBlock, showAnswers, isPdfEnv, setIframeSrc, setIsModalOpen } =
        props;

    let apartados = getApartados(exerciseBlock.fields.content);
    let first_apartado = apartados.shift();

    return (
        <section className="row exerciseBlock exerciseBlock12">
            <div className="col-12">
                {first_apartado.map((contentBlock, index) => {
                    return getContentBlockToShow(
                        contentBlock,
                        showAnswers,
                        `${exerciseBlock.sys.id}-${index}-efd`
                    );
                })}
            </div>
            {apartados.map((apartado, index) => {
                return (
                    <div className="col-3" key={`${exerciseBlock.sys.id}-${index}`}>
                        {apartado.map((contentBlock, indexb) => {
                            return getContentBlockToShow(
                                contentBlock,
                                showAnswers,
                                `${exerciseBlock.sys.id}-${index}-${indexb}`,
                                isPdfEnv,
                                setIsModalOpen,
                                setIframeSrc
                            );
                        })}
                    </div>
                );
            })}
        </section>
    );
};