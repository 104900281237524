/*global chrome*/
import React, {useState} from "react";
import {DeliveryClient} from "../Clients";

export const ContentBlockEmbeddedAsset = (props) => {
  const {
    id,
    clau,
    viewAsAnswer,
    showAnswers,
    isPdfEnv,
    setIsModalOpen,
    setIframeSrc,
  } = props;

  const [asset, setAsset] = useState(null)

  if(!asset){
    DeliveryClient.getOrCreate()
    .getAsset(id)
    .then((assetD) => {
      if(assetD){
        setAsset(assetD);
      }
    });
  }

  const openInModal = (url) => {
    setIframeSrc(url);
    setIsModalOpen(true);
  };

  const getImageNameFromUrl = (url) => {
    const splited = url.split("/");
    return splited[splited.length - 1];
  };

  let classNames = getClassNames(asset);
  let style = getCSSstring(asset?.description);

  let img = (
    <img
      onClick={
        () => {
          openInModal(
            `https://setupimages.jumpmath.es/?name=${getImageNameFromUrl(asset?.fields.file.url)}`
          );
        }
      }
      key={clau}
      crossOrigin="*"
      src={asset?.fields.file.url}
      id={`id_${id}`}
      className={`imatge ${classNames} clickable`}
      alt={`${id}`}
      style={style}
    />
  );

  if (viewAsAnswer) {
    let answer_class = `answer ${showAnswers ? "show" : "hide"} mt-0`;
    return <div className={`${answer_class}`}>{img}</div>;
  }

  return <div className="centered">{img}</div>;
};

const getClassNames = (asset) => {
  let classNames = "";

  if (asset) {
    let aspectRatio = asset.width / asset.height;

    if (aspectRatio < 0.4) {
      classNames = "vertical r2_5";
    } else if (aspectRatio < 0.6) {
      classNames = "vertical r3_5";
    } else if (aspectRatio < 0.85) {
      classNames = "vertical r4_5";
    } else if (aspectRatio < 1.1) {
      classNames = "quadrat";
    } else if (aspectRatio < 1.25) {
      classNames = "horitzontal r5_4";
    } else if (aspectRatio < 2.0) {
      classNames = "horitzontal r2_1";
    } else if (aspectRatio < 3.0) {
      classNames = "horitzontal r3_1";
    } else if (aspectRatio < 4.0) {
      classNames = "horitzontal r4_1";
    } else if (aspectRatio < 5.0) {
      classNames = "horitzontal r5_1";
    } else if (aspectRatio < 6.0) {
      classNames = "horitzontal r6_1";
    } else {
      classNames = "horitzontal r7_1";
    }

    if (asset.mimeType === "image/svg+xml") {
      classNames += " svg";
    }
  }

  return classNames;
};

const getCSSstring = (string) => {
  let keyValues = null;

  if (string) {
    string = string.trim();

    if (string.charAt(string.length - 1) === ";") {
      string = string.replace(/;$/, "");

      let css_json = `{"${string
        .replace(/: /g, ":")
        .replace(/; /g, ";")
        .replace(/;/g, '", "')
        .replace(/:/g, '": "')}"}`;

      let obj = null;

      try {
        obj = JSON.parse(css_json);
      } catch (e) {}

      if (obj != null) {
        keyValues = Object.keys(obj).map((key) => {
          let camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
          return { [camelCased]: obj[key] };
        });
      }
    }
  }

  return keyValues != null ? Object.assign({}, ...keyValues) : null;
};
