import $ from "jquery";

export const getContentBlockClasses = (contentBlock, indexb, contentBlocks) => {
  const imageColClass = getImageBlockColClass(
    contentBlock,
    indexb,
    contentBlocks
  );
  const imageCenterRightClass = getImageCenterRightClass(
    contentBlock,
    indexb,
    contentBlocks
  );
  const isEmptyClass = getIsEmptyClass(contentBlock, indexb, contentBlocks);
  return `${imageColClass} ${imageCenterRightClass} ${isEmptyClass}`;
};

const getIsEmptyClass = (contentBlock, indexb, contentBlocks) => {
  let isEmptyClass = "";
  // if (contentBlock.nodeType === "paragraph") {
  //   console.log("contentBlock.content", contentBlock.content);
  // }

  if (
    contentBlock.nodeType === "paragraph" &&
    !contentBlock.content.some((c) => c?.value?.trim().length > 0)
  ) {
    // console.log("contentBlock.content", contentBlock.content);
    return "hide";
  }
  if (
    contentBlock.content[0]?.value?.trim().toLowerCase() === "**start_row**" ||
    contentBlock.content[0]?.value?.trim().toLowerCase() === "**end_row**"
  ) {
    return "hide";
  }
  if (
    indexb < contentBlocks.length - 1 &&
    contentBlocks[indexb + 1].nodeType === "embedded-asset-block"
  ) {
    if (
      contentBlock.content[0]?.value?.trim().toLowerCase() === "**center**" ||
      contentBlock.content[0]?.value?.trim().toLowerCase() === "**right**"
    ) {
      return "hide";
    }
  }
  return isEmptyClass;
};

const getImageCenterRightClass = (contentBlock, indexb, contentBlocks) => {
  let imageCenterRightClass = "";
  if (contentBlock.nodeType === "embedded-asset-block") {
    if (indexb > 0) {
      const specifierValue = contentBlocks[indexb - 1].content[0]?.value
        ?.trim()
        .toLowerCase();
      if (specifierValue === "**center**") {
        return "center";
      }
      if (specifierValue === "**right**") {
        return "right";
      }
    }
  }
  return imageCenterRightClass;
};

const imageIsInBetweenRowStatements = (contentBlock, indexb, contentBlocks) => {
  if (contentBlock.nodeType !== "embedded-asset-block") return false;
  let isBeforeEndRowStatement = false;
  for (let i = indexb + 1; i < contentBlocks.length; i++) {
    if (
      contentBlocks[i].content[0]?.value?.trim().toLowerCase() ===
      "**start_row**"
    ) {
      break;
    }
    if (
      contentBlocks[i].content[0]?.value?.trim().toLowerCase() === "**end_row**"
    ) {
      isBeforeEndRowStatement = true;
      break;
    }
  }
  let isAfterBeginRowStatement = false;
  for (let i = indexb - 1; i > -1; i--) {
    if (
      contentBlocks[i].content[0]?.value?.trim().toLowerCase() === "**end_row**"
    ) {
      break;
    }
    if (
      contentBlocks[i].content[0]?.value?.trim().toLowerCase() ===
      "**start_row**"
    ) {
      isAfterBeginRowStatement = true;
      break;
    }
  }
  return isAfterBeginRowStatement && isBeforeEndRowStatement;
};

const getNum = (contentBlock, indexb, contentBlocks) => {
  let num = 0;
  if (contentBlock.nodeType === "embedded-asset-block") {
    if (!imageIsInBetweenRowStatements(contentBlock, indexb, contentBlocks))
      return ++num;
    let isEndRowStatementReached = false;
    const getForwardCondition = (i) => {
      if (i === contentBlocks.length) return false;
      if (isEndRowStatementReached) return false;
      if (contentBlocks[i].nodeType === "embedded-asset-block") return true;
      if (
        i < contentBlocks.length - 1 &&
        getImageCenterRightClass(contentBlocks[i + 1], i + 1, contentBlocks)
          .length
      )
        return true;
      return true;
    };
    for (let i = indexb; getForwardCondition(i); i++) {
      if (contentBlocks[i].nodeType === "embedded-asset-block") {
        num++;
        continue;
      }
      if (
        contentBlocks[i].content[0]?.value?.trim().toLowerCase() ===
        "**end_row**"
      ) {
        isEndRowStatementReached = true;
      }
    }
    let isStartRowStatementReached = false;
    const getBackwardCondition = (i) => {
      if (i <= -1) return false;
      if (isStartRowStatementReached) return false;
      if (contentBlocks[i].nodeType === "embedded-asset-block") return true;
      if (
        i < contentBlocks.length - 1 &&
        getImageCenterRightClass(contentBlocks[i + 1], i + 1, contentBlocks)
          .length
      )
        return true;
      return true;
    };
    for (let i = indexb - 1; getBackwardCondition(i); i--) {
      if (contentBlocks[i].nodeType === "embedded-asset-block") {
        num++;
        continue;
      }
      if (
        contentBlocks[i].content[0]?.value?.trim().toLowerCase() ===
        "**start_row**"
      ) {
        isStartRowStatementReached = true;
      }
    }
  }
  return num;
};

const getImageBlockColClass = (contentBlock, indexb, contentBlocks) => {
  const num = getNum(contentBlock, indexb, contentBlocks);
  let colClass;
  switch (num) {
    case 1:
      colClass = "col-12 image-container";
      break;
    case 2:
      colClass = "col-6 image-container";
      break;
    case 3:
      colClass = "col-4 image-container";
      break;
    case 4:
      colClass = "col-3 image-container";
      break;
    default:
      colClass = "col-12";
      break;
  }
  return colClass;
};
