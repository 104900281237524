import {toggleNotes, toggleFullscreen} from "./LessonPlayer.ui";

const LessonPlayerTopMenu = () => {
    return <div className="slideNotesTopMenu">
        <button onClick={toggleNotes} className="notas">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.6484 6.51562C17.8038 6.51562 17.9529 6.57736 18.0628 6.68724C18.1726 6.79713 18.2344 6.94616 18.2344 7.10156C18.2344 7.25696 18.1726 7.406 18.0628 7.51588C17.9529 7.62577 17.8038 7.6875 17.6484 7.6875H11.7891C11.6337 7.6875 11.4846 7.62577 11.3747 7.51588C11.2649 7.406 11.2031 7.25696 11.2031 7.10156C11.2031 6.94616 11.2649 6.79713 11.3747 6.68724C11.4846 6.57736 11.6337 6.51562 11.7891 6.51562H17.6484ZM17.6484 10.0312C17.8038 10.0312 17.9529 10.093 18.0628 10.2029C18.1726 10.3128 18.2344 10.4618 18.2344 10.6172C18.2344 10.7726 18.1726 10.9216 18.0628 11.0315C17.9529 11.1414 17.8038 11.2031 17.6484 11.2031H11.7891C11.6337 11.2031 11.4846 11.1414 11.3747 11.0315C11.2649 10.9216 11.2031 10.7726 11.2031 10.6172C11.2031 10.4618 11.2649 10.3128 11.3747 10.2029C11.4846 10.093 11.6337 10.0312 11.7891 10.0312H17.6484ZM18.2344 14.1328C18.2344 13.9774 18.1726 13.8284 18.0628 13.7185C17.9529 13.6086 17.8038 13.5469 17.6484 13.5469H11.7891C11.6337 13.5469 11.4846 13.6086 11.3747 13.7185C11.2649 13.8284 11.2031 13.9774 11.2031 14.1328C11.2031 14.2882 11.2649 14.4372 11.3747 14.5471C11.4846 14.657 11.6337 14.7188 11.7891 14.7188H17.6484C17.8038 14.7188 17.9529 14.657 18.0628 14.5471C18.1726 14.4372 18.2344 14.2882 18.2344 14.1328ZM17.6484 17.0625C17.8038 17.0625 17.9529 17.1242 18.0628 17.2341C18.1726 17.344 18.2344 17.493 18.2344 17.6484C18.2344 17.8038 18.1726 17.9529 18.0628 18.0628C17.9529 18.1726 17.8038 18.2344 17.6484 18.2344H11.7891C11.6337 18.2344 11.4846 18.1726 11.3747 18.0628C11.2649 17.9529 11.2031 17.8038 11.2031 17.6484C11.2031 17.493 11.2649 17.344 11.3747 17.2341C11.4846 17.1242 11.6337 17.0625 11.7891 17.0625H17.6484Z"
                    fill="#F9F9FA"
                />
                <path
                    d="M21.75 5.34375C21.75 4.72215 21.5031 4.12601 21.0635 3.68647C20.624 3.24693 20.0279 3 19.4062 3H5.34375C4.72215 3 4.12601 3.24693 3.68647 3.68647C3.24693 4.12601 3 4.72215 3 5.34375L3 19.4062C3 20.0279 3.24693 20.624 3.68647 21.0635C4.12601 21.5031 4.72215 21.75 5.34375 21.75H19.4062C20.0279 21.75 20.624 21.5031 21.0635 21.0635C21.5031 20.624 21.75 20.0279 21.75 19.4062V5.34375ZM7.6875 4.17188V20.5781H5.34375C5.03295 20.5781 4.73488 20.4547 4.51511 20.2349C4.29534 20.0151 4.17188 19.7171 4.17188 19.4062V5.34375C4.17188 5.03295 4.29534 4.73488 4.51511 4.51511C4.73488 4.29534 5.03295 4.17188 5.34375 4.17188H7.6875ZM8.85938 4.17188H19.4062C19.7171 4.17188 20.0151 4.29534 20.2349 4.51511C20.4547 4.73488 20.5781 5.03295 20.5781 5.34375V19.4062C20.5781 19.7171 20.4547 20.0151 20.2349 20.2349C20.0151 20.4547 19.7171 20.5781 19.4062 20.5781H8.85938V4.17188Z"
                    fill="#F9F9FA"
                />
            </svg>
        </button>

        <button onClick={toggleFullscreen} className="fullscreen">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.19995 4.00001C3.19995 3.78784 3.28424 3.58436 3.43427 3.43433C3.5843 3.2843 3.78778 3.20001 3.99995 3.20001H8.79995C9.01212 3.20001 9.21561 3.2843 9.36564 3.43433C9.51567 3.58436 9.59995 3.78784 9.59995 4.00001C9.59995 4.21219 9.51567 4.41567 9.36564 4.5657C9.21561 4.71573 9.01212 4.80001 8.79995 4.80001H4.79995V8.80001C4.79995 9.01219 4.71567 9.21567 4.56564 9.3657C4.41561 9.51573 4.21212 9.60001 3.99995 9.60001C3.78778 9.60001 3.5843 9.51573 3.43427 9.3657C3.28424 9.21567 3.19995 9.01219 3.19995 8.80001V4.00001ZM14.4 4.00001C14.4 3.78784 14.4842 3.58436 14.6343 3.43433C14.7843 3.2843 14.9878 3.20001 15.2 3.20001H20C20.2121 3.20001 20.4156 3.2843 20.5656 3.43433C20.7157 3.58436 20.8 3.78784 20.8 4.00001V8.80001C20.8 9.01219 20.7157 9.21567 20.5656 9.3657C20.4156 9.51573 20.2121 9.60001 20 9.60001C19.7878 9.60001 19.5843 9.51573 19.4343 9.3657C19.2842 9.21567 19.2 9.01219 19.2 8.80001V4.80001H15.2C14.9878 4.80001 14.7843 4.71573 14.6343 4.5657C14.4842 4.41567 14.4 4.21219 14.4 4.00001ZM3.99995 14.4C4.21212 14.4 4.41561 14.4843 4.56564 14.6343C4.71567 14.7844 4.79995 14.9878 4.79995 15.2V19.2H8.79995C9.01212 19.2 9.21561 19.2843 9.36564 19.4343C9.51567 19.5844 9.59995 19.7878 9.59995 20C9.59995 20.2122 9.51567 20.4157 9.36564 20.5657C9.21561 20.7157 9.01212 20.8 8.79995 20.8H3.99995C3.78778 20.8 3.5843 20.7157 3.43427 20.5657C3.28424 20.4157 3.19995 20.2122 3.19995 20V15.2C3.19995 14.9878 3.28424 14.7844 3.43427 14.6343C3.5843 14.4843 3.78778 14.4 3.99995 14.4ZM20 14.4C20.2121 14.4 20.4156 14.4843 20.5656 14.6343C20.7157 14.7844 20.8 14.9878 20.8 15.2V20C20.8 20.2122 20.7157 20.4157 20.5656 20.5657C20.4156 20.7157 20.2121 20.8 20 20.8H15.2C14.9878 20.8 14.7843 20.7157 14.6343 20.5657C14.4842 20.4157 14.4 20.2122 14.4 20C14.4 19.7878 14.4842 19.5844 14.6343 19.4343C14.7843 19.2843 14.9878 19.2 15.2 19.2H19.2V15.2C19.2 14.9878 19.2842 14.7844 19.4343 14.6343C19.5843 14.4843 19.7878 14.4 20 14.4Z"
                    fill="#F9F9FA"
                />
            </svg>
        </button>
    </div>;
}

export default LessonPlayerTopMenu;