import React from "react";
import {Routes, Route, useSearchParams} from "react-router-dom";
import Home from "./Pages/Home";
import Grades from "./Pages/Grades";
import Grade from "./Pages/Grade";
import StagingPage from "./Pages/StagingPage";
import PDFPage from "./Pages/PDFPage";
import Lessons from "./Pages/Lessons/Lessons";
import RedirectManager from "./Pages/RedirectManager";
import {NotAuthorized} from "./Pages/NotAuthorized";
import {useCookies} from "react-cookie";
import LessonPlayer from "./Pages/LessonPlayer/LessonPlayer";
import LessonAPBook from "./Pages/LessonAPBook/LessonAPBook";
import LessonPlan from "./Pages/LessonPlan/LessonPlan";
import PublicPage from "./Pages/PublicPage";

const App = (props) => {
  let [searchParams] = useSearchParams();

  const [cookies, setCookie] = useCookies([]);
  let cookieAgeInHours = 48

  let authorizedDomains = process.env.REACT_APP_EMBED_AUTHORIZED_DOMAINS.split(',')

  function isAuthorized(){
    if(process.env.REACT_APP_AUTHENTICATION_ENABLED == "false")
      return true;

    let isEmbedded = window.self !== window.top;

    let origin = null
    try{
      origin = window.parent.location.origin
    }catch(e){
      console.log("error when getting origin")
    }

    let isEmbeddedAndAuthorized = isEmbedded
    if(origin){
      isEmbeddedAndAuthorized = isEmbedded && authorizedDomains?.includes(origin)
    }


    let isNotEmbeddedButAuthorized = false
    if(searchParams.get("password") && process.env.REACT_APP_PASSWORD == searchParams.get("password")){
      setCookie('password', searchParams.get("password"), { maxAge: cookieAgeInHours * 60 * 60})
      isNotEmbeddedButAuthorized = true
    }
    if(cookies.password && process.env.REACT_APP_PASSWORD == cookies.password){
      setCookie('password', cookies.password, { maxAge: cookieAgeInHours * 60 * 60})
      isNotEmbeddedButAuthorized = true
    }

    return isEmbeddedAndAuthorized || isNotEmbeddedButAuthorized
  }

  return (
      isAuthorized() ?
        <Routes>
            <Route path="" element={<RedirectManager />} />
            <Route path="/:locale">
              <Route path="" element={<RedirectManager />} />
              <Route path="public">
                <Route path="" element={<PublicPage element={<Home env={'public'} />} />} />
                <Route path="grades" element={<PublicPage element={<Grades env={'public'} />} />} />
                <Route path="grade/:id" element={<PublicPage element={<Grade env={'public'} />} />} />
                <Route path="lessons" element={<PublicPage element={<Lessons env={'public'} />} />} />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-player"
                    element={<PublicPage element={<LessonPlayer env={'public'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-plan"
                    element={<PublicPage element={<LessonPlan env={'public'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-apbook"
                    element={<PublicPage element={<LessonAPBook env={'public'} />} />}
                />
              </Route>
              <Route path="staging">
                <Route path="" element={<StagingPage element={<Home env={'staging'} />} />} />
                <Route path="grades" element={<StagingPage element={<Grades env={'staging'} />} />} />
                <Route
                    path="grade/:id"
                    element={<StagingPage element={<Grade env={'staging'} />} />}
                />
                <Route path="lessons" element={<StagingPage element={<Lessons env={'staging'} />} />} />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-player"
                    element={<PublicPage element={<LessonPlayer env={'staging'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-plan"
                    element={<StagingPage element={<LessonPlan env={'staging'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-apbook"
                    element={<StagingPage element={<LessonAPBook env={'staging'} />} />}
                />
              </Route>
              <Route path="pdf">
                <Route path="" element={<PDFPage element={<Home env={'pdf'} />} />} />
                <Route path="grades" element={<PDFPage element={<Grades env={'pdf'} />} />} />
                <Route
                    path="grade/:id"
                    element={<PDFPage element={<Grade env={'pdf'} />} />}
                />
                <Route path="lessons" element={<PDFPage element={<Lessons env={'pdf'} />} />} />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-player"
                    element={<PDFPage element={<LessonPlayer env={'pdf'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-plan"
                    element={<PDFPage element={<LessonPlan env={'pdf'} />} />}
                />
                <Route
                    path="lessons/:grade_id/:unit_id/:id/lesson-apbook"
                    element={<PDFPage element={<LessonAPBook env={'pdf'} />} />}
                />
              </Route>
            </Route>
        </Routes>
          : <NotAuthorized />
  )
}

export default App;
