import { toggleNotes } from "./LessonPlayer.ui";
import { useTranslation } from "react-i18next";

const LessonPlayerSidebar = () => {
  const { t } = useTranslation();
  return (
    <div className="slideNotesSidebar">
      <div className="slideNotesSidebarHeader">
        <div>{t("player.notes")}</div>
      </div>
      <div className="slideNotesSidebarContent"></div>
      <button onClick={toggleNotes} className="closeButton">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.55 6L6 17.55M17.55 17.55L6 6L17.55 17.55Z"
            stroke="#374047"
            strokeWidth="1.65"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default LessonPlayerSidebar;
