import React, { useEffect } from "react";
import { richText, RICHTEXT_WITH_HTML } from "../../Utils/richText";
import { renderKatex } from "../../Utils/renderKatex";
import { renderToStaticMarkup } from "react-dom/server";

const traverseNodes = (contentBlock) => {
  for (let i = 0; i < contentBlock.content.length; i++) {
    if (contentBlock.content[i].marks) {
      if (contentBlock.content[i].marks.length > 0) {
        let classes = "";

        for (let mark of contentBlock.content[i].marks) {
          if (mark.type === "bold") classes += "bold ";
        }

        contentBlock.content[i].value = renderToStaticMarkup(
          <span className={classes}>{contentBlock.content[i].value}</span>
        );
        contentBlock.content[i].marks = [];
        contentBlock.content[i].asIs = true;
        contentBlock.asHTML = true;
      }
    } else {
      traverseNodes(contentBlock.content[i]);
    }
  }
};

const ContentBlockTable = (props) => {
  useEffect(() => {
    renderKatex(document.body);
  });
  const { contentBlock } = props;
  traverseNodes(contentBlock);
  // console.log("contentBlock", contentBlock);
  return (
    <div className="rich-table">
      {richText(contentBlock, RICHTEXT_WITH_HTML)}
    </div>
  );
};
export default ContentBlockTable;
