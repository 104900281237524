import React, { Component } from "react";
import "./Footer.css";
import { withRouter } from "../Utils/withRouter";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      language: "",
      env: "master",
    };

    this.changeLanguage = this.changeLanguage.bind(this);
    this.goToPublic = this.goToPublic.bind(this);
    this.goToPdf = this.goToPdf.bind(this);
  }

  componentDidMount() {
    const { i18n, t } = this.props;

    if (this.props.match.params.locale) {
      this.props.i18n.changeLanguage(this.props.match.params.locale);
    }

    this.setState({
      language: this.props.i18n.language,
      env: this.props.env,
    });

    window.footer_state = this.state;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.t !== prevProps.t) {
      this.setState({ language: this.props.i18n.language });
    }
    if (this.props.env !== prevProps.env) {
      this.setState({ env: this.props.env });
    }

    window.footer_state = this.state;
  }

  changeLanguage(e) {
    this.props.i18n.changeLanguage(e.target.value);

    let env =
      this.props.env == "master" || this.props.env === "development"
        ? ""
        : `/${this.props.env}`;
    this.props.router.navigate(`/${e.target.value}/staging${env}`);
  }

  goToPublic() {
    const { router, t } = this.props;

    if (router && router.location && router.location.pathname) {
      router.navigate(router.location.pathname.replace("staging", "public"));
    }
  }

  goToPdf() {
    const { router, t } = this.props;

    if (router && router.location && router.location.pathname) {
      router.navigate(router.location.pathname.replace("staging", "pdf"));
    }
  }

  render() {
    const { i18n, t } = this.props;

    return (
      <div>
        <footer className="footer">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-3"></div>
              <div className="col-6 text-center">
                <button
                  className={`btn btn-sm btn-outline-dark me-3`}
                  onClick={this.goToPublic}
                >
                  /public
                </button>
                <button
                  className={`btn btn-sm btn-outline-dark`}
                  onClick={this.goToPdf}
                >
                  /pdf
                </button>
              </div>
              <div className="col-3 text-end">
                {/* <button
                  className={`btn btn-sm ${
                    this.state.language == "ca"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  } me-2`}
                  onClick={this.changeLanguage}
                  value="ca"
                >
                  ca
                </button>
                <button
                  className={`btn btn-sm ${
                    this.state.language == "es-ES"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  } me-2`}
                  onClick={this.changeLanguage}
                  value="es-ES"
                >
                  es-ES
                </button> */}
                <button
                  className={`btn btn-sm ${
                    this.state.language == "en-CA"
                      ? "btn-primary"
                      : "btn-outline-primary"
                  }`}
                  onClick={this.changeLanguage}
                  value="en-CA"
                >
                  en-CA
                </button>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(Footer);
