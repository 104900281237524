import { Link } from "react-router-dom";
import { ButtonGroup, Dropdown } from "react-bootstrap";

export const LessonsListFragment = (props) => {
  const { items, t, unitId, gradeId, i18n, env } = props;

  return (
    <table className="table table-bordered table-striped table-hover">
      <tbody>
        {items?.map((lesson, index) => {
          let unit =
            lesson.linkedFrom?.unitsCollection?.items?.length > 0
              ? lesson.linkedFrom.unitsCollection.items[0]
              : null;
          let grade =
            unit?.linkedFrom?.gradesCollection?.items?.length > 0
              ? unit.linkedFrom.gradesCollection.items[0]
              : null;

          let _unitId = unit != null ? unit.sys.id : unitId;
          let _gradeId = grade != null ? grade.sys.id : gradeId;

          if (_unitId !== undefined && _gradeId !== undefined) {
            if (lesson.__typename === "Lessons") {
              return (
                <tr key={index}>
                  <td className={`title`}>
                    {/* {unitId != null ? `${index + 1}.  ` : ""} */}
                    {`${lesson.idName} ${lesson.name}`}

                    <strong>
                      {!lesson.teachingBlocksCollection.items.length && " *"}
                    </strong>
                  </td>
                  {/* <td>{lesson.idName}</td> */}
                  <td className={"text-end"}>
                    <Link
                      className="btn btn-primary btn-sm mx-2"
                      to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/lesson-plan`}
                    >
                      {t("navbar.guia_docente")}
                    </Link>
                    <Link
                      className="btn btn-primary btn-sm mx-2"
                      to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/lesson-player`}
                    >
                      {t("lessons.presentacion_aula")}
                    </Link>

                    {/* <Dropdown as={ButtonGroup} size="sm" className="mx-2">
                      <Link
                        className="btn btn-primary btn-sm ps-2 pe-1"
                        to={`../lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/lesson-apbook`}
                      >
                        {t("navbar.libro_alumno")}
                      </Link>

                      <Dropdown.Toggle
                        split
                        variant="primary"
                        id="dropdown-split-basic"
                      />

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href={`/${i18n.language}/${env}/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/lesson-apbook?answers=true`}
                        >
                          {t("navbar.libro_alumno_soluciones")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`/${i18n.language}/${env}/lessons/${_gradeId}/${_unitId}/${lesson.sys.id}/lesson-apbook?answers=true&meta=true`}
                        >
                          {t("navbar.libro_alumno_info")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          } else {
            return (
              <tr key={index}>
                <td className={`title`}>{lesson.name}</td>
                <td className="text-end" colSpan={`3`}>
                  {t("lessons.no_units")}
                </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};
