import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { parseUrl } from "@aws-sdk/url-parser";
import { S3RequestPresigner } from "@aws-sdk/s3-request-presigner";
import { HttpRequest } from "@aws-sdk/protocol-http";
import { formatUrl } from "@aws-sdk/util-format-url";

import React, { useEffect, useLayoutEffect, useState } from "react";
import LessonNavbar from "../LessonNavbar";

import Logo from "../../images/LogoJM.jpg";
import { useQuery } from "@apollo/client";
import { GraphQLClient } from "../Clients";
import BlackLoader from "../../images/black-loader.gif";
import { getLessonPlan } from "./LessonPlan.graphQL";
import { getContentBlockClasses } from "../LessonPlayer/LessonPlayer.functions";
import { renderKatex } from "../../Utils/renderKatex";
import { getApartados, getContentBlockToShow } from "../UI/ExerciseBlocks";

const MAX_SLIDES = 10;

const LessonPlan = (props) => {
  // const navigate = useNavigate();
  let match = { params: useParams() };
  let { i18n, t } = useTranslation();

  const [lesson, setLesson] = useState({});
  const [competence, setCompetence] = useState([]);
  const [vocabulary, setVocabulary] = useState([]);
  const [standards, setStandards] = useState([]);
  const [classroomMaterials, setClassroomMaterials] = useState([]);
  const [manipulatives, setManipulatives] = useState([]);

  const [practiceTime, setPracticeTime] = useState(0);
  const [mentalTime, setMentalTime] = useState(0);
  const [teachingTime, setTeachingTime] = useState(0);

  const isPublic = props.env === "public";
  const isPdf = props.env === "pdf";

  const showOnlyTheContent = isPublic || isPdf;

  let globalState = {
    id: match.params.id,
    grade_id: match.params.grade_id,
    unit_id: match.params.unit_id,
    lesson: lesson,
    functions: {
      setLesson: setLesson,
    },
  };

  useLayoutEffect(() => {
    renderKatex(document.body);
  });

  function processData() {
    let fields = {
      standardsCollection: [],
      vocabularyCollection: [],
      classroomMaterialsCollection: [],
      competence: [],
      manipulativesCollection: [],
    };

    let typesOfBlock = ["mmmBlocksCollection", "teachingBlocksCollection"];

    for (let typeOfBlock of typesOfBlock) {
      if (lesson[typeOfBlock].items) {
        for (let block of Array.from(lesson[typeOfBlock].items)) {
          switch (typeOfBlock) {
            case "mmmBlocksCollection":
              setMentalTime(mentalTime + block.duration);
              break;
            case "teachingBlocksCollection":
              setTeachingTime(teachingTime + block.duration);
              break;
          }

          for (let [fieldName, fieldArray] of Object.entries(fields)) {
            if (block[fieldName] != null) {
              switch (fieldName) {
                case "competence":
                  let word = block[fieldName];
                  if (!fieldArray.some((w) => w.sys.id === word.sys.id)) {
                    fieldArray.push(word);
                  }
                  break;
                default:
                  for (let word of Array.from(block[fieldName].items)) {
                    if (!fieldArray.some((w) => w.sys.id === word.sys.id)) {
                      fieldArray.push(word);
                    }
                  }
                  break;
              }
            }
          }
        }
      }
    }

    setPracticeTime(45 - mentalTime - teachingTime);

    setStandards(fields["standardsCollection"]);
    setVocabulary(fields["vocabularyCollection"]);
    setClassroomMaterials(fields["classroomMaterialsCollection"]);
    setCompetence(fields["competence"]);
    setManipulatives(fields["manipulativesCollection"]);
  }

  useEffect(() => {
    if (Object.keys(lesson).length != 0) {
      processData();
    }
  }, [lesson]);

  async function getAWSUrl(file, folder) {
    let bucket = process.env.REACT_APP_AWS_BUCKET;
    let region = process.env.REACT_APP_AWS_DEFAULT_REGION;
    let credentials = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const s3ObjectUrl = parseUrl(
      `https://${bucket}.s3.${region}.amazonaws.com/${folder}/${file}`
    );
    const presigner = new S3RequestPresigner({
      credentials,
      region,
      sha256: Sha256,
    });
    const url = await presigner.presign(new HttpRequest(s3ObjectUrl));

    return formatUrl(url);
  }

  const Standards = (props) => {
    let words = standards.map((w) => w.code);
    return (
      <div>
        <strong>{`${t("docente.standards")}: ${words.join(", ")}`}</strong>
      </div>
    );
  };

  const Vocabulary = (props) => {
    let words = vocabulary
      .map((w) => w.name)
      .sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
    return <div>{words.join(", ")}</div>;
  };

  const Competences = (props) => {
    let words = competence.map((w) => w.name);
    return <div>{words.join(", ")}</div>;
  };

  const ClassroomMaterials = (props) => {
    let words = classroomMaterials.map((w) => w.name);
    return <div>{words.join(", ")}</div>;
  };

  async function openPdf(file) {
    const url = await getAWSUrl(file, "contents/manipulatives");
    window.location.assign(url);
  }

  const Manipulatives = (props) => {
    // let words = manipulatives.map((w) => w.name);
    // const [linksPdf, setLinksPdf] = useState([]);
    // useEffect(() => {
    //   manipulatives.forEach(
    //     (m) =>
    //       m.linkPdf &&
    //       m.linkPdf.includes(".pdf") &&
    //       setLinksPdf(async (links) => [...links, await getPdfURL(m.linkPdf)])
    //   );
    // }, []);
    // let i = 0;
    return (
      <div>
        <ul>
          {manipulatives.map((m) =>
            m.linkPdf && m.linkPdf.includes(".pdf") ? (
              <li key={m.name}>
                <a
                  href={""}
                  role="link"
                  onClick={(e) => {
                    e.preventDefault();
                    openPdf(m.linkPdf);
                  }}
                >
                  {m.name}
                </a>
              </li>
            ) : (
              <li key={m.name}>{m.name}</li>
            )
          )}
        </ul>
      </div>
    );
  };

  const Objective = (props) => {
    const { content } = props;
    // console.log("content", content);
    return (
      <div>
        {getApartados(content).map((apartado, index) => {
          return (
            <div key={`slideNote-${index}`}>
              {apartado.map((contentBlock, indexb) => {
                return getContentBlockToShow(
                  contentBlock,
                  false,
                  `slideNote-${index}-${indexb}`,
                  content.references
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const PreviousKnowledge = (props) => {
    const { content } = props;

    return (
      <div>
        {getApartados(content).map((apartado, index) => {
          return (
            <div key={`slideNote-${index}`}>
              {apartado.map((contentBlock, indexb) => {
                return getContentBlockToShow(
                  contentBlock,
                  false,
                  `slideNote-${index}-${indexb}`,
                  content.references
                );
              })}
              <p></p>
            </div>
          );
        })}
      </div>
    );
  };

  const {
    data: data,
    error: error,
    loading: loading,
  } = useQuery(getLessonPlan(globalState.id, i18n.language), {
    client: GraphQLClient.getOrCreate(
      process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || "dev"
    ),
    errorPolicy: "ignore",
  });

  if (loading)
    return (
      <div align="center" className="pt-5">
        {" "}
        <img src={BlackLoader} alt="Loader" />{" "}
      </div>
    );

  if (error) return <p>Error :(</p>;

  if (Object.keys(globalState.lesson).length === 0) {
    // console.log("data.lessons", data.lessons);
    setLesson(data.lessons);
  }

  const MMMBlocks = (props) => {
    const mmmBlocks = props.mmmBlocks ?? lesson.mmmBlocksCollection.items;
    // console.log("mmmBlocks", mmmBlocks);
    if (mmmBlocks) {
      const content = (
        <>
          {mmmBlocks.filter((m) => m.objective.json).length === 0 ? (
            ""
          ) : (
            <>
              {props.isOnlyContentMode ? (
                <div className="slideNotes">
                  <h4 className="title">
                    {props.index + 1}. {i18n.t("docente.calculo_mental")}
                  </h4>
                </div>
              ) : null}
              <div className="row mmmBlock-objective mt-4 mb-4 p-3 mx-0 shadow">
                <div className="col-12">
                  <h6 className="h5 text-primary bold">
                    {i18n.t("docente.objetivos")}
                  </h6>
                  {mmmBlocks?.map((mmmBlock, index) => {
                    return (
                      <div className="" key={`docente-objetivos-${index}`}>
                        {getApartados(mmmBlock.objective.json).map(
                          (apartado, index) => {
                            return (
                              <div
                                className={"objective-apartado"}
                                key={`objective-apartado-${index}`}
                              >
                                {apartado.map((contentBlock, indexb) => {
                                  return getContentBlockToShow(
                                    contentBlock,
                                    false,
                                    `objective-apartado-${index}-${indexb}`,
                                    mmmBlock.objective.references
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}

          <div>
            {mmmBlocks?.map((mmmBlock, index) => {
              let numSlideNotes = 0;
              for (let i = 1; i <= MAX_SLIDES; i++) {
                if (
                  mmmBlock[`teachersNotes${i}`] != null ||
                  mmmBlock[`slideText${i}`] != null
                ) {
                  numSlideNotes++;
                }
              }

              let slideNotes = [];
              for (let i = 1; i <= numSlideNotes; i++) {
                slideNotes.push(
                  <SlideNote
                    key={`${mmmBlock.id}${i}`}
                    teachingBlock={mmmBlock}
                    index={i}
                  />
                );
              }

              return (
                <div
                  className={`slideNotes ${
                    numSlideNotes === 1 ? "single" : "multiple"
                  }`}
                  key={`mm-slide-notes-${index}`}
                >
                  <div className="slides mt-2">{slideNotes}</div>
                </div>
              );
            })}
          </div>
        </>
      );
      return props.isOnlyContentMode ? (
        content
      ) : (
        <section className="calculoMental mb-5">
          <h4 className="section-title">{i18n.t("docente.calculo_mental")}</h4>
          {content}
        </section>
      );
    } else {
      return <section className="my-3"></section>;
    }
  };

  const SlideNote = (props) => {
    const { teachingBlock, index } = props;

    let slideText = {
      references: teachingBlock[`slideText${index}`]?.references,
      apartados: getApartados(teachingBlock[`slideText${index}`]?.json),
    };

    let teachersNotes = {
      references: teachingBlock[`teachersNotes${index}`]?.references,
      apartados: getApartados(teachingBlock[`teachersNotes${index}`]?.json),
    };

    function getClassNameByPosition(position, totalApartados) {
      switch (totalApartados) {
        case 1:
          return "col-12";
        case 2:
          return "col-6";
        default:
          return position === 0 ? "col-12" : "col-6";
      }
    }

    return (
      <div className="slideNote">
        <div className="notes">
          <h5 className="title">
            {i18n.t("docente.diapositiva")} {index}
          </h5>
          {teachersNotes.apartados.map((apartado, index) => {
            return (
              <div
                className={getClassNameByPosition(
                  index,
                  teachersNotes.apartados.length
                )}
                key={`slideNote-${index}`}
              >
                {apartado.map((contentBlock, indexb) => {
                  return getContentBlockToShow(
                    contentBlock,
                    false,
                    `slideNote-${index}-${indexb}`,
                    teachersNotes.references
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="slide">
          <div className="title">{lesson.name}</div>
          <div className="text">
            <div className="row">
              {slideText.apartados.map((apartado, index) => {
                return (
                  <div
                    className={getClassNameByPosition(
                      index,
                      slideText.apartados.length
                    )}
                    key={`slideNote-${index}`}
                  >
                    {apartado.map((contentBlock, indexb, contentBlocks) => {
                      const contentBlockClasses = getContentBlockClasses(
                        contentBlock,
                        indexb,
                        contentBlocks
                      );
                      return (
                        <div className={contentBlockClasses}>
                          {getContentBlockToShow(
                            contentBlock,
                            false,
                            `slideNote-${index}-${indexb}`,
                            slideText.references
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TeachingBlocks = (props) => {
    const teachingBlocks =
      props.teachingBlocks ?? lesson.teachingBlocksCollection.items;
    if (teachingBlocks) {
      const content = (
        <div>
          {teachingBlocks?.map((teachingBlock, index) => {
            let numSlideNotes = 0;
            for (let i = 1; i <= MAX_SLIDES; i++) {
              if (
                teachingBlock[`teachersNotes${i}`] != null ||
                teachingBlock[`slideText${i}`] != null
              ) {
                numSlideNotes++;
              }
            }

            let slideNotes = [];
            for (let i = 1; i <= numSlideNotes; i++) {
              slideNotes.push(
                <SlideNote
                  key={`${teachingBlock.id}${i}`}
                  teachingBlock={teachingBlock}
                  index={i}
                  single={numSlideNotes === 1}
                />
              );
            }

            return (
              <div
                className={`slideNotes ${
                  numSlideNotes === 1 ? "single" : "multiple"
                }`}
                key={`dd-slide-notes-${index}`}
              >
                <h4 className="title">
                  {(props.index ?? index) + 1}. {teachingBlock.title}
                </h4>
                <div className="slides">{slideNotes}</div>
              </div>
            );
          })}
        </div>
      );
      return props.isOnlyContentMode ? (
        content
      ) : (
        <section className="descubrimientoGuiado mb-5">
          <h4 className="section-title">
            {i18n.t("docente.descubrimiento_guiado")}
          </h4>
          {content}
        </section>
      );
    } else {
      return <section className="card my-3 border-0"></section>;
    }
  };

  const TRExtensionBlocks = (props) => {
    if (lesson.trExtensionBlocksCollection.items?.length > 0) {
      return (
        <section className="descubrimientoGuiado mb-5">
          <h4 className="section-title">{i18n.t("docente.ampliacion")}</h4>
          {lesson.trExtensionBlocksCollection.items.map(
            (trExtensionBlock, index) =>
              trExtensionBlock.title ? (
                <TeachingBlocks
                  key={trExtensionBlock.id}
                  teachingBlocks={[trExtensionBlock]}
                  isOnlyContentMode
                  index={index + lesson.teachingBlocksCollection.items.length}
                />
              ) : (
                <MMMBlocks
                  mmmBlocks={[trExtensionBlock]}
                  key={trExtensionBlock.id}
                  isOnlyContentMode
                  index={index + lesson.teachingBlocksCollection.items.length}
                />
              )
          )}
        </section>
      );
    } else {
      return <section className="card my-3 border-0"></section>;
    }
  };

  return (
    <div className="container lesson docente mb-5">
      {showOnlyTheContent ? null : (
        <div className="row">
          <div className="col-12">
            <LessonNavbar globalState={globalState} />
          </div>
        </div>
      )}

      <div className="row mt-5">
        <div className="col-12">
          <section className="cabecera mb-5">
            <div className="d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column">
                <h2 className="card-title d-inline-flex font-weight-bold lesson-title">
                  {`${lesson.code} ${lesson.name}`}
                </h2>
                <div className="lesson-page mb-0">
                  <strong>{lesson.apBookPage}</strong>
                </div>
                {/*<h4 className="card-title d-inline-flex font-weight-bold lesson-title">{t('docente.duracion')}</h4>*/}
                <div className="lesson-page mb-0">
                  <Standards />
                </div>
              </div>
              <img className="imgLogo" src={Logo} alt="foto Logo" />
            </div>
          </section>

          <section className="antesEmpezar">
            <h4 className="section-title">{i18n.t("docente.antes_empezar")}</h4>

            <div className="row contenido mt-4 mb-4 p-3 mx-0 shadow">
              {lesson.objective == null ? (
                ""
              ) : (
                <div className="col-6">
                  <h6 className="h5 text-primary bold">
                    {i18n.t("docente.objetivos")}
                  </h6>
                  <Objective content={lesson.objective.json} />
                </div>
              )}

              {lesson.previousKnowledge == null ? (
                ""
              ) : (
                <div className="col-6">
                  <h6 className="h5 text-primary bold">
                    {i18n.t("docente.conocimientos_previos")}
                  </h6>
                  <PreviousKnowledge content={lesson.previousKnowledge.json} />
                </div>
              )}

              <div
                className={`col-6 ${competence.length === 0 ? "d-none" : ""}`}
              >
                <h6 className="h5 text-primary bold">
                  {i18n.t("docente.competencias")}
                </h6>
                <Competences />
              </div>

              <div
                className={`col-6 ${vocabulary.length === 0 ? "d-none" : ""}`}
              >
                <h6 className="h5 text-primary bold">
                  {i18n.t("docente.vocabulario")} ({vocabulary.length}{" "}
                  {vocabulary.length === 1
                    ? i18n.t("docente.palabra")
                    : i18n.t("docente.palabras")}
                  )
                </h6>
                <Vocabulary />
              </div>

              <div
                className={`col-6 ${
                  classroomMaterials.length === 0 ? "d-none" : ""
                }`}
              >
                <h6 className="h5 text-primary bold">
                  {i18n.t("docente.material_aula")}
                </h6>
                <ClassroomMaterials />
              </div>

              <div
                className={`col-6 ${
                  manipulatives.length === 0 ? "d-none" : ""
                }`}
              >
                <h6 className="h5 text-primary bold">
                  {i18n.t("docente.material_manipulable")}
                </h6>
                <Manipulatives />
              </div>
            </div>
          </section>
        </div>
      </div>

      {!!lesson.mmmBlocksCollection?.items.length && (
        <div className="row">
          <div className="col-12">
            <MMMBlocks />
          </div>
        </div>
      )}

      {!!lesson.teachingBlocksCollection?.items.length && (
        <div className="row">
          <div className="col-12">
            <TeachingBlocks />
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <TRExtensionBlocks />
        </div>
      </div>

      <div className="row apBook">
        <div className="col-12">
          <div className="border-0 bg-transparent">
            <h5 className="barra-titulo">
              {i18n.t("docente.practica_evaluacion")}
            </h5>
            <h6 className="lesson-tit3le p-3">{lesson.apBookPage}</h6>
            <h2 style={{ display: "none" }}>adding css</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPlan;
