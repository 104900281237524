import React, {Component} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import "../scss/staging/App.scss";

class StagingPage extends Component{
    render() {
        const {element} = this.props;

        return (
            <div className="layout staging">
                <Navbar />
                {element}
                <Footer />
            </div>
        );
    }
}

export default StagingPage;