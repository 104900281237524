import { nextSlide, prevSlide, refreshProgressBar } from "./LessonPlayer.ui";
import $ from "jquery";

export const initCarousel = (globalState) => {
  $(document).ready(function () {
    $(".slideNotesSidebar").css("right", -$(".slideNotesSidebar").width());

    globalState.carousel.on("change", function (index) {
      globalState.functions.setSlidePosition({
        current: index,
        previous: globalState.slidePosition.current,
      });
    });

    globalState.carousel.on("select", function (index) {
      globalState.functions.setSlidePosition({
        current: index,
        previous: globalState.slidePosition.current,
      });
    });

    $(window).resize(function () {
      refreshProgressBar(globalState, false);
    });

    $(document).keydown(function (event) {
      if (event.which === 39) {
        nextSlide(globalState.carousel);
      }
      if (event.which === 37) {
        prevSlide(globalState.carousel);
      }
    });
  });
};

export const loadFromJson = (globalState) => {
  let loadedJson = JSON.parse(globalState.jsonToLoad);

  if (loadedJson) {
    let slideGroupsToLoad = [];
    for (let slideGroupFromJson of loadedJson) {
      let slideGroup = globalState.slideGroups.filter(
        (s) => s.id == slideGroupFromJson.id
      )[0];
      if (slideGroup) {
        let slidesToLoad = [];
        for (let slideFromJson of slideGroupFromJson.slides) {
          let slide = slideGroup.slides.filter(
            (s) => s.index == slideFromJson
          )[0];
          if (slide) {
            slidesToLoad.push(slide);
          }
        }
        slideGroup.slides = slidesToLoad;
        slideGroupsToLoad.push(slideGroup);
      }
    }
    globalState.functions.setSlideGroups(
      putInOrderSlideGroups(slideGroupsToLoad)
    );
  }
};

export const randomizeSlides = (globalState) => {
  let _slideGroups = [];
  _slideGroups.push(
    globalState.slideGroups.filter((s) => s.type == "initialSection")
  );
  for (let _slideGroup of globalState.slideGroups
    .filter((s) => s.type != "initialSection" && s.type != "finalSection")
    .sort((a, b) => 0.5 - Math.random())) {
    _slideGroup.slides.sort((a, b) => 0.5 - Math.random());
    _slideGroups.push(_slideGroup);
  }
  _slideGroups.push(
    globalState.slideGroups.filter((s) => s.type == "finalSection")
  );

  _slideGroups = putInOrderSlideGroups(_slideGroups.flat());

  console.log(_slideGroups);

  globalState.functions.setSlideGroups(_slideGroups);
};

export const putInOrderSlideGroups = (_slideGroups) => {
  let slidePosition = 0;
  let sectionPosition = 0;

  for (let _slideGroup of _slideGroups) {
    _slideGroup.initialSlidePosition = slidePosition;
    if (_slideGroup.type != "initialSection") {
      if (_slideGroup.type !== "mmmBlocksSection" && sectionPosition === 0) {
        sectionPosition++;
      }
      _slideGroup.sectionPosition = sectionPosition++;
    }
    for (let _slide of _slideGroup.slides) {
      _slide.slidePosition = slidePosition;
      slidePosition++;
    }
  }

  return _slideGroups;
};

export const exportSlides = (globalState) => {
  let json = [];
  for (let slideGroup of globalState.slideGroups) {
    let _slideGroup = {
      id: slideGroup.id,
      slides: [],
    };
    for (let _slide of slideGroup.slides) {
      _slideGroup.slides.push(_slide.index);
    }
    json.push(_slideGroup);
  }
  console.log(JSON.stringify(json));
};

export const createContent = (globalState, i18n) => {
  const MAX_SLIDES = 10;

  let slideGroups = [];

  function addInitialSlideNote() {
    let slideGroup = {
      type: "initialSection",
      id: "initialSection",
      title: i18n.t("aula.inicio"),
      sectionTitle: i18n.t("aula.inicio"),
      slides: [],
      sectionIcon: "icon_calculo_mental",
    };
    slideGroup.slides.push({
      type: "initialNote",
      slideGroup: slideGroup,
      index: 1,
    });

    slideGroups.push(slideGroup);
  }

  addInitialSlideNote.call(this);

  if (globalState.lesson?.fields.mmmBlocks) {
    let slideGroup = {
      type: "mmmBlocksSection",
      id: "mmmBlocksSection",
      title: i18n.t("docente.calculo_mental"),
      sectionTitle: i18n.t("docente.calculo_mental"),
      slides: [],
      sectionIcon: "icon_calculo_mental",
    };
    for (const mmmBlock of globalState.lesson.fields.mmmBlocks) {
      for (let i = 1; i <= MAX_SLIDES; i++) {
        if (
          mmmBlock.fields[`teachersNotes${i}`] != null ||
          mmmBlock.fields[`slideText${i}`] != null
        ) {
          slideGroup.slides.push({
            type: "slideNote",
            slideBlock: mmmBlock,
            slideGroup: slideGroup,
            index: i,
          });
        }
      }
    }

    slideGroups.push(slideGroup);
  }

  if (globalState.lesson?.fields.teachingBlocks) {
    for (const teachingBlock of globalState.lesson.fields.teachingBlocks) {
      let slideGroup = {
        type: "teachingBlocksSection",
        id: teachingBlock.sys.id,
        title: teachingBlock.fields.title,
        sectionTitle: i18n.t("docente.descubrimiento_guiado"),
        slides: [],
        sectionIcon: "icon_descubrimiento_guiado",
      };

      for (let i = 1; i <= MAX_SLIDES; i++) {
        if (
          teachingBlock.fields[`teachersNotes${i}`] != null ||
          teachingBlock.fields[`slideText${i}`] != null
        ) {
          slideGroup.slides.push({
            type: "slideNote",
            slideBlock: teachingBlock,
            slideGroup: slideGroup,
            index: i,
          });
        }
      }

      slideGroups.push(slideGroup);
    }
  }

  if (globalState.lesson?.fields.trExtencionBlocks) {
    for (const trExtensionBlock of globalState.lesson.fields
      .trExtencionBlocks) {
      let slideGroup = {
        type: "trExtensionsSection",
        title:
          trExtensionBlock.fields.title ?? i18n.t("docente.calculo_mental"),
        sectionTitle: i18n.t("alumno.ampliacion"),
        slides: [],
        sectionIcon: "icon_descubrimiento_guiado",
      };

      for (let i = 1; i <= MAX_SLIDES; i++) {
        if (
          trExtensionBlock.fields[`teachersNotes${i}`] != null ||
          trExtensionBlock.fields[`slideText${i}`] != null
        ) {
          slideGroup.slides.push({
            type: "slideNote",
            slideBlock: trExtensionBlock,
            slideGroup: slideGroup,
            index: i,
          });
        }
      }

      slideGroups.push(slideGroup);
    }
  }

  function addFinalSlideNote() {
    let slideGroup = {
      type: "finalSection",
      id: "finalSection",
      title: i18n.t("aula.libro_practica_evaluacion"),
      sectionTitle: i18n.t("docente.practica_evaluacion"),
      slides: [],
      sectionIcon: "icon_practica_evaluacion",
    };
    slideGroup.slides.push({
      type: "finalNote",
      slideGroup: slideGroup,
      index: 1,
    });

    slideGroups.push(slideGroup);
  }

  addFinalSlideNote.call(this);

  let _slideGroups = putInOrderSlideGroups(slideGroups);

  globalState.functions.setInitialSlideGroups(_slideGroups);
  globalState.functions.setSlideGroups(_slideGroups);
};
