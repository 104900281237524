import {goToSection} from "./LessonPlayer.ui";

const NavItem = ({ navItem, globalState }) => {

    return (
        <div className={`navItem navItem${navItem.slideGroup.sectionPosition}`}
             key={`navItem_${navItem.slideGroup.sectionPosition}`}>
            <button
                className="circle"
                data-initialslideposition={navItem.slideGroup.initialSlidePosition}
                data-sectionslides={navItem.slideGroup.slides.length}
                data-sectionposition={navItem.slideGroup.sectionPosition}
                onClick={(e) => goToSection(e, globalState)}
            >
                {navItem.slideGroup.sectionPosition}
            </button>
            <div className="text">{navItem.slideGroup.title}</div>
        </div>
    );
};

export default NavItem;