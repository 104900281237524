import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-CA",
    // lng: "es-ES",
    resources: {
      ca: {
        translation: require("./locales/ca/translations.json"),
      },
      "es-ES": {
        translation: require("./locales/es/translations.json"),
      },
      "en-CA": {
        translation: require("./locales/en/translations.json"),
      },
    },
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
    },
    // ns: ['translations'],
    // defaultNS: 'translations'
  });

i18n.languages = ["ca", "es-ES", "en-CA"];

export default i18n;
