import React from "react";
import {getApartados, getContentBlockHelperValues, getContentBlockToShow} from "../ExerciseBlocks";

export const ExerciseBlock4 = (props) => {
    const { exerciseBlock, showAnswers, isPdfEnv, setIframeSrc, setIsModalOpen } =
        props;
    let col1_apartados = getApartados(exerciseBlock.fields.content);
    let col2_apartado = col1_apartados.pop();
    return (
        <section className="row exerciseBlock exerciseBlock4">
            <div className="col-6">
                {col1_apartados.map((apartado, index) => {
                    return (
                        <div className="col-12" key={`${exerciseBlock.sys.id}-${index}`}>
                            <div className="container">
                                <div className="row card">
                                    {apartado.map((contentBlock, indexb, contentBlocks) => {
                                        const { isAbsolute, isAnswer, isEmpty } =
                                            getContentBlockHelperValues(
                                                contentBlock,
                                                indexb,
                                                contentBlocks
                                            );
                                        return (
                                            <div
                                                className={`col-12 ${isAbsolute ? "is-absolute" : ""} ${
                                                    (isAnswer && !showAnswers) || isEmpty ? "hide" : ""
                                                }`}
                                                key={`${exerciseBlock.sys.id}-${index}-${indexb}`}
                                            >
                                                {getContentBlockToShow(
                                                    contentBlock,
                                                    showAnswers,
                                                    `${exerciseBlock.sys.id}-${index}-${indexb}`,
                                                    isPdfEnv,
                                                    setIsModalOpen,
                                                    setIframeSrc
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="col-6">
                <div className="container">
                    <div className="row card">
                        {col2_apartado.map((contentBlock, indexb, contentBlocks) => {
                            const { isAbsolute, isAnswer, isEmpty } =
                                getContentBlockHelperValues(
                                    contentBlock,
                                    indexb,
                                    contentBlocks
                                );
                            return (
                                <div
                                    className={`col-12 ${isAbsolute ? "is-absolute" : ""} ${
                                        (isAnswer && !showAnswers) || isEmpty ? "hide" : ""
                                    }`}
                                    key={`${exerciseBlock.sys.id}-wsddf-${indexb}`}
                                >
                                    {getContentBlockToShow(
                                        contentBlock,
                                        showAnswers,
                                        `${exerciseBlock.sys.id}-wsddf-${indexb}`,
                                        isPdfEnv,
                                        setIsModalOpen,
                                        setIframeSrc
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
};